.media-preview-input-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

.media-preview-content-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.media-preview-or-separator {
  background-color: #ececec;
  padding: 6px 6px;
  height: 28px;
  width: 28px;
  color: #7c7c7c;
  border-radius: 50%;
  cursor: default;
  line-height: 16px;
}

.media-preview-container {
  margin-top: 16px;
  margin-bottom: 32px;
}

.media-preview-image-wrapper {
  display: flex;
  justify-content: center;
}

.preview-image-container {
  max-width: 100%;
  max-height: 40vh;
}