.tab-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 24px;
    cursor: pointer;

    transition: background-color 0.2s ease-in-out;
}

.tab-container:hover {
    background-color: #edecfe;

    transition: background-color 0.2s ease-in-out;
}

.tab-container-selected {
    background-color: #E5E3FB;

    transition: background-color 0.2s ease-in-out;
}

.tab-icon {
    stroke: #707479;
    width: 20px;

    transition: stroke 0.2s ease-in-out;
}

.tab-icon-selected {
    stroke: #4338ca;

    transition: stroke 0.2s ease-in-out;
}

.tab-label {
    color: #707479;
    font-size: 14px;

    transition: color 0.2s ease-in-out;
}

.tab-label-selected {
    color: #4338ca;

    transition: color 0.2s ease-in-out;
}

@media (max-width: 768px) {
    .tab-label {
        font-size: 12px;
        line-height: 13px;
    }

    .tab-container {
        gap: 6px;
        padding: 8px 12px;
    }

    .tab-icon {
        width: 16px;
    }
}