.login-footer-container {
  display: flex;
  gap: 52px;
  background-color: #333333;
  padding: 24px 10%;
}

.login-footer-description {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.login-footer-menu {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.login-footer-description-text {
  color: #fff;
  font-family: "Days One";
  text-align: start;
}

.login-footer-title {
  font-family: "Days One", Sans-serif !important;
  font-size: 17px;
  font-weight: 700;
  border: solid 2px #FFCC33;
  color: #FFCC34;
  padding: 0 8px;
  width: fit-content;
}

.login-footer-logo {
  max-width: 68px;
}

.login-footer-link {
  display: flex;
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .login-footer-container{
    gap: 28px;
  }

  .login-footer-logo {
    max-width: 52px;
  }

  .login-footer-description-text {
    font-size: 14px;
  }

  .login-footer-container {
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .login-footer-container {
    flex-direction: column;
    padding: 16px 5%;
  }
}