.success-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f9f9f9;
}

.success-page-central-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;
    background: #fff;
    border-radius: 16px;
    margin: 0 16px;
}

.success-page-success-icon {
    width: 88px;
    height: 88px;
    stroke: #06D6A0;
}

.success-page-welcome-message {
    margin-top: 50px;
    font-size: 24px;
    width: 100%;
}

.success-page-description {
    font-size: 14px;
    color: #8a8a8a;
    margin-top: 12px;
}

.success-page-highlighted-text {
    color: #FFCC33;
}

@media (max-width: 600px) {
    .success-page-central-container {
        padding: 24px;
    }
}