.settings-wrapper {
    margin-top: 84px;
    height: calc(100vh - 84px);
    background: #f1f1f1;
    display: flex;
}

.settings-container {
    background: #fff;
    padding: 24px;
    margin: auto;
    width: fit-content;
    margin-top: 100px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    position: relative;
    overflow: hidden;
}

.settings-avatar-banner {
    width: 100%;
    height: 100px;
    /* Or any height you want */
    background: red;
    /* This will be overridden by .area, but keep it for fallback */
    border-radius: 8px;
    position: relative;
    /* Make this the positioning context for .area and .circles */
    overflow: hidden;
    /* Ensure circles don't overflow outside the banner */
}

.settings-avatar {
    border-radius: 50%;
    border: solid 4px #fff;
    margin-top: -50px;
    height: 100px;
    width: 100px;
    z-index: 1;
}

.settings-user-name {
    margin-top: 24px;
    font-weight: 700;
}

.settings-user-email {
    font-size: 13px;
    color: #9d9d9d;
}

.settings-plan-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    gap: 63px;
}

.settings-info-box {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 4px;
    flex-direction: column;
    padding: 13px;
    background: #f1f1f1;
    border-radius: 8px;
    width: 150px;
}

.settings-info-box-big {
    font-size: 16px;
    font-weight: 700;
}

.settings-info-box-small {
    font-size: 13px;
    color: #9d9d9d;
}

.settings-deactivate {
    margin-top: 44px;
    align-self: start;
    width: 100%;
    color: #5b5b5b;
    background-color: transparent;
}

.settings-activate {
    margin-top: 44px;
    align-self: start;
    width: 100%;
    background-color: #FFCC34;
    color: #000;
}

@media (max-width: 768px) {
    .settings-wrapper {
        margin-top: 60px;
        height: calc(100vh - 60px);
    }

    .settings-container {
        margin: 50px 0 20px 20px;
        width: calc(100% - 40px);
        height: fit-content;
    }

    .settings-plan-container {
        gap: 22px;
    }

    .settings-info-box-big {
        font-size: 14px;
    }
}

.area {
    background: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-100%) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}