.connect-media-top-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    align-items: center;
    padding: 16px 0;
}

.connect-media-container {
    width: 100%;
    max-width: 880px;
    padding: 0 16px;
}

.periodic-jobs-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    padding: 12px;
}

.connect-media-add-media-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    transition: all 0.3s ease;
}

.media-input-description {
    color: #6a6a6a;
    font-size: 14px;
    max-width: 400px;
    min-width: 200px;
}

.periodic-jobs-list-description {
    font-size: 14px;
    color: #6a6a6a;
    margin-top: 16px;
}

.connect-media-container button:focus,
.connect-media-container input:focus {
    outline: 2px solid #4a90e2;
    outline-offset: 2px;
}

.periodic-jobs-list-empty {
    text-align: center;
    padding: 24px;
    color: #6a6a6a;
    font-style: italic;
}

@media (max-width: 768px) {
    .connect-media-add-media-wrapper {
        flex-direction: column-reverse;
        align-items: end;
        width: 95%;
    }

    .media-input-title {
        align-self: end;
        line-height: 42px;
    }

}

@media (max-width: 480px) {
    .connect-media-container {
        padding: 0 8px;
    }

    .media-input-description {
        min-width: unset;
    }
}