.radio-options-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.radio-option-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.radio-option {
  width: 20px;
  height: 20px;
}

.radio-option-label {

}