.periodic-entry {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background-color: #ececec;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.periodic-entry-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  display: flex;
  align-items: center;
}

.periodic-entry-searching-disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

.periodic-entry-description-container {
  display: flex;
  gap: 16px;
  max-width: 80%;
}

.periodic-entry-thumbnail {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.periodic-entry-description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 60px);
  overflow: hidden;
}

.periodic-entry-description {
  align-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
}

.periodic-entry-next-run {
  font-size: 12px;
  color: #8a8a8a;
}

.periodic-entry-delete-button {
  height: 44px;
  opacity: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.periodic-entry-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.periodic-entry-action-button {
  white-space: nowrap;
  opacity: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.periodic-entry-delete-button,
.periodic-entry-action-button {
  transition: opacity 0.2s ease-in-out;
}

.periodic-entry:hover .periodic-entry-delete-button,
.periodic-entry-disabled:hover .periodic-entry-delete-button,
.periodic-entry:hover .periodic-entry-action-button {
  opacity: 1;
}


@media (max-width: 768px) {
  .periodic-entry {
    border-radius: 8px;
    padding: 8px;
  }

  .periodic-entry-description-container {
    max-width: 50%;
  }

  .periodic-entry-thumbnail {
    width: 44px;
    height: 44px;
  }

  .periodic-entry-next-run {
    font-size: 11px;
  }

  .periodic-entry-delete-button,
  .periodic-entry-action-button {
    opacity: 1;
  }
}

@media print {
  .periodic-entry {
    background-color: white;
    border: 1px solid #ddd;
  }

  .periodic-entry-delete-button,
  .periodic-entry-action-button {
    display: none;
  }
}
