/* TopBar Styles */
.top-bar {
  top: 0;
  /* Align the top edge of the bar with the top of the viewport */
  left: 0;
  /* Align the left edge of the bar with the left of the viewport */
  width: 100%;
  /* Make it full width */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #333333;
  z-index: 1;
  /* more options: #333333 (charcoal gray), 
  /* z-index: 1000;    Ensure it sits on top of other content */
}

.fixed-bar {
  position: fixed;
}

.ailight-logo {
  width: 240px;
  /* Adjust as needed */
  height: 60px;
  margin: 0;
  align-items: center;
}

.main-logo {
  padding: 0 12px;
}

.top-bar-right {
  display: flex;
  align-items: center;
}

.user-name {
  margin-right: 10px;
  font-size: 30px;
  color: black;
  font-weight: bold;
}

.user-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: solid #FFCC34;
  border-width: 4px;
  transition: transform 0.3s ease, border-width 0.3s ease;
}

.user-image:hover {
  transform: scale(1.1); /* Slightly enlarges the image */
}

.logout-icon {
  margin-inline-end: 50px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: none;
}

.top-bar-front-layer {
  position: absolute;
  z-index: 10000;
}

@media (max-width: 767px) {
  body {
    margin: 0;
  }

  .user-image {
    width: 44px;
    height: 44px;
  }

  .logout-icon {
    margin-inline-end: 0px;
    width: 32px !important;
    height: 32px !important;
  }

  .ailight-logo {
    width: 124px;
    height: 60px;
  }

  .top-bar {
    padding: 0 12px;
  }

  .main-logo {
    padding: 6px;
  }
}