.modal-create-recurring-wrapper {
    width: 680px;
    max-width: 100%;
}

.modal-create-recurring-footer {
    display: flex;
    margin-top: 26px;
    justify-content: space-between;
}

.inputs-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.frame-grid-wrapper {
    max-height: 53vh;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .inputs-wrapper {
        flex-direction: column;
    }

    .frame-grid-wrapper {
        max-height: 29vh;
    }
  }