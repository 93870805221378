.similarity-search-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  max-height: 80vh;
  padding: 0 24px;
  position: relative;
  max-width: 1100px;
}

.videos-search-header {
  flex-shrink: 0; /* Prevents the header from shrinking */
  padding: 10px;
}

.videos-list-container {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  min-height: 0; /* Important for Firefox */
  max-height: calc(80vh - 120px); /* Adjust based on header height */
}

.video-item-wrapper {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
}

/* Add styles for modal context */
.modal-container .similarity-search-results {
  max-height: 100%;
  overflow: hidden;
}

.modal-container .videos-list-container {
  max-height: calc(100% - 120px);
  overflow-y: auto;
}
