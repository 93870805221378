.nav-item {
  display: flex;
  align-items: center;
  line-height: 17px;
  padding: 8px;
  padding-inline-start: 32px;
  cursor: pointer;
  gap: 8px;
  
  transition: 0.2s background-color ease-in-out;
}

.nav-item:hover {
  background-color: #e9e9e9;
  transition: 0.2s background-color ease-in-out;
}

.nav-item-active {
  cursor: default;
  background-color: #FFCC34;
}

.nav-item-active:hover {
  background-color: #FFCC34;
}