.usage-history-top-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    align-items: center;
    padding: 16px 0;
}

.usage-history-container {
    width: 100%;
    max-width: 880px;
    padding: 0 16px;
}

.usage-history-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.usage-history-add-media-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
}

@media (max-width: 768px) {
    .usage-history-add-media-wrapper {
        flex-direction: column-reverse;
        align-items: end;
        width: 128px;
    }

    .media-input-title {
        align-self: end;
        line-height: 42px;
    }
}

.text-image-thumbnail {
    background-color: white;
}