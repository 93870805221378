.ai-detection-widget-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 16px;
    overflow: hidden;
}

.ai-detection-widget-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ai-detection-widget-card-header {
    padding: 16px 20px 8px;
    border-bottom: 1px solid #f0f0f0;
}

.ai-detection-widget-status-header-container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.ai-detection-widget-source-image {
    width: 72px;
    height: 72px;
    border-radius: 8px;
}

.ai-detection-widget-card-title {
    font-size: 16px;
    font-weight: 600;
    color: #4b5563;
    margin: 0;
}

.ai-detection-widget-card-content {
    padding: 16px 20px;
}

.ai-detection-widget-result {
    margin-bottom: 16px;
}

.ai-detection-widget-status-container {
    display: flex;
    gap: 12px;
}

.ai-detection-widget-status-icon {
    width: 24px;
    height: 24px;
}

.ai-detection-widget-status-text {
    flex: 1;
}

.ai-detection-widget-status-heading {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.ai-detection-widget-status-detail {
    font-size: 14px;
    color: #6b7280;
    margin: 0;
}

.ai-icon,
.ai-generated {
    color: #7A5FEF;
}

.human-icon,
.human-created {
    color: #047857;
}

.uncertain-icon,
.uncertain {
    color: #6b7280;
}

.ai-detection-widget-confidence-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.ai-detection-widget-confidence-labels {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.ai-detection-widget-confidence-label {
    color: #6b7280;
}

.ai-detection-widget-confidence-value {
    font-weight: 500;
    color: #374151;
}

.ai-detection-widget-thin-progress-bar-bg {
    width: 100%;
    height: 8px;
    background-color: #ececec;
    border-radius: 999px;
    overflow: hidden;
}

.ai-detection-widget-thin-progress-bar {
    height: 100%;
    border-radius: 999px;
    transition: width 0.5s ease;
}

.ai-detection-widget-low-confidence {
    background-color: #c8c8c8;
}

.ai-detection-widget-moderate-confidence {
    background-color: #c8c8c8;
}

.ai-detection-widget-high-confidence {
    background-color: #c8c8c8;
}

.ai-detection-widget-very-high-confidence {
    background-color: #c8c8c8;
}