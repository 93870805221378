.search-entry {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.2s;
    border: 1px solid #f0f0f0;
    cursor: pointer;
}

.search-entry:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    background: #f9f9f9;
}

.entry-media {
    position: relative;
    width: 120px;
    min-width: 120px;
    height: 120px;
    overflow: hidden;
}

.entry-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.search-entry:hover .entry-thumbnail {
    transform: scale(1.05);
}

.fake-warning {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(255, 82, 82, 0.9);
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.entry-content {
    flex: 1;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.entry-source {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 6px;
}

.source-logo {
    width: 16px;
    height: 16px;
    object-fit: contain;
    border-radius: 3px;
}

.source-domain {
    font-size: 12px;
    color: #666;
}

.entry-title {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.4;
    text-align: start;
}

.entry-link {
    color: #333;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.entry-link:hover {
    color: #4f46e5;
}

.external-icon {
    opacity: 0.6;
}

.entry-metadata {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
}

.metadata-item {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: #666;
}

.similarity {
    border-radius: 16px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
}

.similarity-value {
    font-weight: 600;
    font-size: 13px;
}

.similarity-label {
    margin-left: 4px;
    font-size: 11px;
    opacity: 0.8;
}

.similarity.similarity-high {
    background-color: rgba(52, 211, 153, 0.15);
    color: #047857;
}

.similarity.similarity-medium {
    background-color: rgba(251, 191, 36, 0.15);
    color: #b45309;
}

.similarity.similarity-low {
    background-color: rgba(239, 68, 68, 0.15);
    color: #b91c1c;
}

.entry-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: absolute;
    bottom: 12px;
}

.entry-title-container {
    max-width: 800px;
    overflow: hidden;
    width: 100%;
}

.entry-title-text {
    font-weight: 400;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.entry-similarity {
    align-self: center;
    margin-inline-end: 12px;
}

.badge-container {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
}

.badge-icon {
    width: 14px;
    height: 14px;
}

.badge-reliability {
    background-color: #f3f4f6;
    color: #374151;
}

.badge-high {
    background-color: rgba(52, 211, 153, 0.15);
    color: #047857;
}

.badge-medium {
    background-color: rgba(251, 191, 36, 0.15);
    color: #b45309;
}

.badge-low {
    background-color: rgba(239, 68, 68, 0.15);
    color: #b91c1c;
}

.badge-social {
    background-color: rgba(79, 70, 229, 0.15);
    color: #4338ca;
}

.badge-warning {
    background-color: rgba(239, 68, 68, 0.15);
    color: #b91c1c;
    display: flex;
    align-items: center;
    gap: 4px;
}

.similarity-progressbar {
    width: 70px;
    height: 70px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .search-entry {
        flex-direction: column;
    }

    .entry-media {
        width: 100%;
        height: 180px;
    }

    .entry-metadata {
        flex-wrap: wrap;
    }

    .entry-title-text {
        line-clamp: unset;
        -webkit-line-clamp: unset;
        box-orient: unset;
        -webkit-box-orient: unset;
    }

    .entry-similarity {
        align-self: end;
        margin-inline-end: 12px;
        margin-bottom: 12px;
    }

    .entry-badges {
        bottom: -68px;
    }
}