.main-work-area-wrapper {
  width: inherit;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 84px);
  height: auto;
}

@media (max-width: 768px) {
  .main-work-area-wrapper {
    min-height: calc(100vh - 60px);
    height: auto;
  }
}