.video-description-container {
  background-color: #f0f0f0;
  position: relative;
  width: fit-content;
  border-radius: 12px;
  border: solid 2px #FFCC34;
  overflow: hidden;
  cursor: pointer;
}

.vidoe-description-play-button {
  height: 48px;
  padding: 8px;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
  background: #FFCC34;
  border-radius: 50% 0 0 50%;

  transition: all 0.2s ease-in-out, border-radius 0.4s ease-in-out;
}

.video-description-container:hover .vidoe-description-play-button {
  right: 50%;
  transform: translate(50%, -50%);
  background: #FFCC34;
  border-radius: 50% 50% 50% 50%;

  transition: all 0.2s ease-in-out;
}

.video-description-play-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  stroke: #f0f0f0;
}

.vidoe-description-title {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #8a8a8a;
  line-height: 12px;
  padding: 3px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}