.factContainer {
  border: 0cqmax solid #ccc;
  border-radius: 5px;
  padding: 1px;
  margin-bottom: 10px;
}

.factText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

.factContent {
  flex-grow: 1;
}

.arrow {
  font-size: 18px;
  padding-left: 10px;
}

.collapsibleContent {
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.upArrow {
  transform: rotate(270deg);
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

.downArrow {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

.iconX {
  width: 24px;
  height: 24px;
  margin-top: -2px;
  flex-shrink: 0;
}

.iconV {
  width: 20px;
  height: 20px;
  margin-top: -6px;
  flex-shrink: 0;
}