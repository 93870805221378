.modal-create-connect-media-wrapper {
    width: 680px;
    max-width: 100%;
}

.modal-create-connect-media-footer {
    display: flex;
    margin-top: 26px;
    justify-content: space-between;
}

.input-loader-container {
    position: relative;
    width: 100%;
}

.full-width-button {
    width: 100%;
}

@media (max-width: 768px) {
    .inputs-wrapper {
        flex-direction: column;
    }
}