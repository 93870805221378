.plan-card-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 10px;
  background-color: #fff;
  align-items: center;
  min-height: 560px;
  position: relative;
}

.plan-card-title {
  font-size: 20px;
  font-weight: 700;
}

.plan-card-price {
  font-size: 32px;
  font-weight: 700;
}

.plan-card-subtitle {
  font-size: 14px;
}

.plan-prices-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.plan-card-details-list-entry-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.plan-card-details-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.plan-card-details-list-entry-bullet {
  width: 12px;
  height: 12px;
}

.plan-card-details-list-entry {
  font-size: 14px;
  line-height: 14px;
}

.plan-card-details-per-month {
  font-size: 14px;
  color: #6a6a6a;
}

.plan-card-select-button {
  position: absolute;
  bottom: 17px;
  width: calc(100% - 32px);
}


@media (max-width: 768px) {
  .plan-card-container {
    width: calc(100vw - 60px);
    min-height: 484px;
  }
}
