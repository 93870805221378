.text-analysis-wrapper {
    width: 680px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.text-area-content {
    width: 100%;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: none;
    padding: 8px;
    min-height: 128px;
}

.char-counter {
    display: flex;
    justify-content: end;
    margin-top: -33px;
    font-size: 14px;
    color: #8a8a8a;
    padding: 0 16px 4px 0;
}

.text-analysis-result-title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 75%;
}

.text-analysis-result-description {
    color: #8a8a8a;
    font-size: 14px;
}

.text-analysis-title {
    font-weight: 700;
    font-size: 20px;
}

.text-analysis-result-container {
    background-color: #F5F5F5;
    border-radius: 8px;
}

.text-analysis-result-header {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    border-bottom: solid 1px #ccc;
    align-items: center;
    min-height: 107px;
    position: relative;
}

.text-analysis-result-indicator-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.text-analysis-result-indicator {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 4px #f9f9f9;
    transition: all 0.2s ease-in-out;
}

.text-analysis-result-indicator-label {
    height: 24px;
    min-width: 52px;
    margin-inline-end: -16px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    padding-inline-end: 22px;
    font-size: 16px;
    text-transform: uppercase;
    padding-inline-start: 10px;
    line-height: 25px;
    font-size: 14px;

    transition: all 0.2s ease-in-out;
}

.text-analysis-result-indicator-label:hover {
    height: 36px;
    line-height: 36px;

    transition: all 0.2s ease-in-out;
}

.text-analysis-indicator-green {
    background-color: #06D6A0;
    color: #2c2c2c;
}

.text-analysis-indicator-yellow {
    background-color: #FFD166;
    color: #2c2c2c;
}

.text-analysis-indicator-red {
    background-color: #EF476F;
    color: #fff;
}

.text-analysis-result-body-wrapper {
    max-height: 0px;
    overflow: hidden;

    transition: 0.2s max-height ease-in-out;
}

.text-analysis-result-body-open {
    max-height: 1600px;

    transition: 0.2s max-height ease-in-out;
}

.highlighted-text {
    border-radius: 5px;
    margin-inline-end: 4px;
    cursor: default;
    border: 1px solid #F5F5F5;
    padding: 2px 4px;

    transition: all 0.2s ease-in-out;
}

.highlighted-text:hover {
    border: 1px solid #3a3a3a;

    transition: all 0.2s ease-in-out;
}

.highlighted-text-medium {
    background-color: #FFD166;
}

.highlighted-text-high {
    background-color: #EF476F;
}

.text-analysis-legend-wrapper {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    margin-top: 16px;
}

.text-analysis-legend-element {
    display: flex;
    gap: 4px;
    align-items: center;
}

.text-analysis-legend-circle {
    border-radius: 50%;
    border: 1px solid #000;
    width: 12px;
    height: 12px;
}

.text-analysis-legend-description {
    font-size: 14px;
    color: #8a8a8a;
}

.text-analysis-details-button {
    position: absolute;
    bottom: -29px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d0d0d0;
    padding: 2px 8px;
    border-radius: 5px;
    cursor: pointer;
}

.text-analysis-more-chevron {
    transform: rotate(-90deg);
    transition: transform 0.2s ease-in-out;
}

.text-analysis-more-chevron-open {
    transform: rotate(90deg);
    transition: transform 0.2s ease-in-out;
}

.text-analysis-body-container {
    padding: 16px;
}

.textarea-container {
    position: relative;
    width: 100%;
}

.textarea-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.textarea-loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .text-analysis-wrapper {
        padding: 80px 24px 32px 24px;
        gap: 20px;
    }

    .text-area-content {
        height: 232px;
    }

    .text-analysis-result-title-wrapper {
        width: 64%;
    }

    .text-analysis-result-title {
        font-size: 15px;
    }

    .text-analysis-result-header {
        padding: 10px;
    }
}