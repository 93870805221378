@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

:root {
  --primary: #FFCC33;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.main-container {
  width: 100%;
  display: flex;
}

.left-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.center-container {
  height: 100%;
  width: 26%;
  background-color: rgba(245, 245, 245, 255);
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
  box-shadow: 0px 19px 37px 0px rgba(0, 0, 0, 0.1);
  gap: 10px;
}
.video-text {
  font-size: 21px;
  font-weight: 500;
}
.find-videos {
  font-size: 16px;
  font-weight: 400;
  color: rgba(124, 124, 124, 1);
  margin-top: 8px;
}
.center-container-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.img-container {
  width: 100%;
  height: fit-content;
}

.input-video {
  height: 47px;
  width: 100%;
  outline: none;
  border: none;
  background: rgba(255, 255, 255, 1);
  padding: 10px 15px;
  border-radius: 12px;
}

.img-video {
  height: 54px;
  width: 100px;
  border-radius: 8.76px;
}

.videos-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
  overflow-y: scroll;
}
.videos-container::-webkit-scrollbar {
  display: none;
}
.video-location-link {
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
  color: rgba(3, 126, 229, 1);
}

.video-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  gap: 1rem;
  padding: 19px 30px;
}
.icon-white {
  color: white;
}
.icon-grey {
  color: grey;
}
.left-video-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.video-box:hover {
  background-color: var(--primary);
}
.video-box.active {
  background-color: var(--primary);
}

.right-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  align-items: center;
  justify-items: center;
  height: fit-content;
}

.card-wrapper {
  position: relative;
}

.colored-box {
  min-height: 100%;
  width: 163px;
  background: rgba(194, 245, 228, 1);
  border-radius: 12px;
  padding: 19px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.green-box {
  background: rgba(194, 245, 228, 1);
}
.yellow-box {
  background: rgb(248, 250, 137);
}
.red-box {
  background: rgba(254, 59, 48, 0.15);
}
.gray-box {
  background: rgba(166, 167, 165, 0.15);
}

.colored-box-text {
  font-size: 16px;
  color: rgba(63, 138, 99, 1);
  font-weight: 600;
  text-align: center;
}
.red-text {
  color: rgba(254, 59, 48, 1);
}
.yellow-text {
  color: gray;
}
.colored-box-icon {
  font-size: 30px;
  fill: green;
}
.colored-box-icon-red-icon {
  fill: red;
}
.colored-box-icon-gray-icon {
  fill: rgb(119, 119, 86);
}

.green-button {
  cursor: pointer;
  background-color: rgba(239, 255, 252, 1);
  color: rgba(64, 134, 94, 1);
  border: none;
  border-radius: 6px;
  padding: 5px 11px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
}
.red-button {
  cursor: pointer;
  background-color: #fff4f1;
  border: none;
  border-radius: 6px;
  padding: 5px 11px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(254, 59, 48, 1);
}

.back-btn {
  all: unset;
  padding: 10px 20px;
  border-radius: 8px;
  background: var(--primary);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
}

.mobile-reset-analysis-button {
  padding: 6px 16px;
}

@media screen and (max-width: 1024px) {
  .main-container {
    flex-wrap: wrap;
  }

  .left-container,
  .center-container,
  .right-container {
    width: 100% !important;
    padding: 10px !important;
  }

  .right-container { 
    background-color: #f9f9f9;
  }

  .left-container img {
    width: 100%;
  }

  .videos-container {
    height: fit-content;
  }
}

.tab-wrapper {
  display: none;
}

@media (max-width: 767px) {
  .main-container {
    gap: 0px;
    grid-gap: 0px;
  }

  .mobile-main-container-margin {
    margin-top: 60px;
  }

  .tab-wrapper {
    display: flex;
    width: 100%;
    background-color: #d0d0d0;
    padding: 0 4px 0 0;
  }

  .tab {
    flex: 1;
    background-color: #d0d0d0;
    padding: 8px;
    cursor: pointer;
    height: 34px;
    margin-top: 6px;
  }

  .selected {
    background: #f9f9f9;
    border-radius: 8px 8px 0px 0px;
  }

  .mobile-hide {
    display: none !important;
  }

  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}