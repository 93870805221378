.navbar {
  height: 100px;
  background-color: #dbde8ba1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.logo {
  font-size: 50px;
  font-weight: bold;
  margin: 50px auto;
}

.list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 50px auto;
}

.listItem {
  margin-right: 20px;
  font-size: 25px;
  font-weight: 500;
  cursor: pointer;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.home {
  display: flex;
  padding: 50px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  width: 30%;
  padding: 10px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
}

.img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin: 20px 0px;
}

.desc {
  color: #333;
  margin-bottom: 20px;
  line-height: 25px;
}

.cardButton {
  border: none;
  border-radius: 10px;
  background-color: rgba(53, 16, 102, 0.678);
  padding: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.post {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postImg {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.postDesc {
  padding: 0 100px;
  font-size: 24px;
  font-weight: 300;
  color: gray;
  margin-top: 50px;
}

.postLongDesc {
  padding: 100px;
  padding-top: 50px;
  font-size: 18px;
  line-height: 24px;
}

.login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 80%;
  height: 75%;
  -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.login-input-wrapper {
  display: flex;
  gap: 16px;
}

.left,.right{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.center{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.or{
  border: 2px solid lightgray;
  border-radius: 50%;
  padding: 10px;
  color: gray;
  background-color: white;
  font-weight: bold;
}

.line{
  width: 0.5px;
  height: 70%;
  background-color: lightgray;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.login-button{
  width: 196px;
  padding: 15px 40px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  transition: filter 0.2s;
}

.login-button:hover {
  filter: brightness(90%);
  transition: filter 0.2s;
}

input{
  width: 200px;
  padding: 15px 30px;
  transition: border-color 0.2s;
}

input:hover, input:focus{
  border-color: #8a8a8a;
  transition: border-color 0.2s;
}

.submit {
  width: 200px;
  background-color: #FFCC33;
  color: black;
  font-weight: bold;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  /* align text to center of button*/
  justify-content: center;  
  transition: filter 0.2s;
}

.submit:hover {
  filter: brightness(95%);
  transition: filter 0.2s;
}

.link{
  color: inherit;
  text-decoration: none;
}

.login-msg{
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

.container {
  display: flex;
}

.leftHome {
  padding-top: 140px;
  flex: 0 0 2%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.rightHome {
  padding-top: 130px;
  padding-left: 130px;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.result-container {
  flex-grow: 1;
}

.imageSearchContainer{
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.polaroid {
  /* width: 250px; */
  /* width: 90%;
  padding: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
div img {
  /* width: 100%; */
  height: 100%;
  object-fit: cover; /* or 'contain' depending on your needs */
}

footer {
    background-color: #f1f1f1;
    text-align: center;
    padding: 20px 0;
    width: 100%;
    bottom: 0;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
}

.main-container {
  display: flex;
}

.hyper-link {
  color: hotpink;
  font-size: 14px;
  cursor: pointer;
}

.hyper-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1279px) {
  .wrapper {
    width: 80%;
  }
    
  .login-input-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .center {
    display: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin-top: 16px;
    padding: 40px 16px;
  }

  .login {
    flex-direction: column;
    height: unset;
  }

  footer {
    padding: 0;
  }

  .login-button {
    margin-bottom: 0;
  }

  .login-msg{
    margin: 0;
  }

  .result-container {
    width: 100%;
  }
}