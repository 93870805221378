.simple-dropdown-list {
  list-style: none;
  margin: 8px 0 0 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
  border-radius: 5px;
  background: #333333;
  overflow: hidden;
  border: 1px solid #f9f9f9;
  width: 200px;
}

.simple-dropdown-item {
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  color: #fff;
  background: #333333;
  transition: background-color 0.2s ease-in-out;
}

.simple-dropdown-item:hover {
  background-color: #474747;
  transition: background-color 0.2s ease-in-out;
}

.simple-dropdown-item-read-only {
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  color: #ccc;
  background: #333333;
  cursor: default;
}

.simple-dropdown-item-separator {
  border-top: 1px solid #f9f9f9;;
}

.simple-dropdown-item-icon {
  stroke: #fff;
}