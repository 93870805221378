.alert-modal-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.alert-modal-description {
    color: #6a6a6a;
    font-size: 14px;
    max-width: 400px;
    min-width: 200px;
    margin-bottom: 20px;
}

.alert-modal-button {
    width: 100%;
    background-color: #06D6A0;
}