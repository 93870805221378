.image-analysis-dashboard-container {
  padding: 24px;
  background-color: #f9fafb;
  min-height: 100vh;
}

.image-analysis-dashboard-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  color: #1f2937;
}

.image-analysis-dashboard-grid {
  display: grid;
  gap: 24px;
  max-width: 92rem;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .image-analysis-dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
    align-content: start;
  }
}

@media (min-width: 1024px) {
  .image-analysis-dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .image-analysis-dashboard-container {
    min-height: unset;
    padding: 12px 24px 0 0;
  }
}

@media (max-width: 1625px) {
  .image-analysis-dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}