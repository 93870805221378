.recurring-media-empty-state-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 20px;
}

.recurring-media-empty-state-title {
    font-size: 24px;
}

.recurring-media-empty-state-description {
    font-size: 14px;
    text-align: center;
    color: #8a8a8a;
}

.recurring-media-empty-state-image {
    width: 50%;
    max-width: 512px
}