.searchResult {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

.searchResult a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.searchResult img {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  border-radius: 3px;
}

.iconX {
  width: 24px;
  height: 24px;
  margin-right: 2px;
  flex-shrink: 0;
}

.iconV {
  width: 18px;
  height: 18px;
  margin-top: -6px;
  margin-left: 5px;
  margin-right: 2px;
  flex-shrink: 0;
}