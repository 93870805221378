/* MetadataAnalysisWidget.css */
.image-analysis-dashboard-widget-container {

}

.image-analysis-dashboard-widget-container:hover {
    background-color: #f9fafb;
}

/* Empty State */
.image-analysis-dashboard-empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: #6b7280;
    text-align: center;
}

.image-analysis-dashboard-empty-icon {
    width: 32px;
    height: 32px;
    color: #9ca3af;
    margin-bottom: 12px;
}

/* Header */
.image-analysis-dashboard-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
}

.image-analysis-dashboard-icon-container {
    padding: 10px;
    border-radius: 9999px;
    margin-right: 16px;
    display: flex;
}

.image-analysis-dashboard-status-icon {
    width: 20px;
    height: 20px;
}

.image-analysis-dashboard-red-icon {
    color: #dc2626;
}

.image-analysis-dashboard-yellow-icon {
    color: #eab308;
}

.image-analysis-dashboard-blue-icon {
    color: #2563eb;
}

.image-analysis-dashboard-title-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.image-analysis-dashboard-title {
    font-size: 1rem;
    font-weight: 600;
}

.image-analysis-dashboard-text-red {
    color: #991b1b;
}

.image-analysis-dashboard-text-yellow {
    color: #854d0e;
}

.image-analysis-dashboard-text-blue {
    color: #1e40af;
}

.image-analysis-dashboard-badge {
    font-size: 0.75rem;
    padding: 2px 8px;
    border-radius: 9999px;
}

.image-analysis-dashboard-badge-red {
    background-color: #fee2e2;
    color: #991b1b;
}

.image-analysis-dashboard-badge-yellow {
    background-color: #fefcbf;
    color: #854d0e;
}

.image-analysis-dashboard-badge-blue {
    background-color: #dbeafe;
    color: #1e40af;
}

.image-analysis-dashboard-description {
    font-size: 0.875rem;
    color: #4b5563;
    margin-top: 4px;
}

.image-analysis-dashboard-link-icon {
    width: 16px;
    height: 16px;
    color: #9ca3af;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.image-analysis-dashboard-widget-container:hover .image-analysis-dashboard-link-icon {
    opacity: 1;
}

/* Items List */
.image-analysis-dashboard-items-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.image-analysis-dashboard-item {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid;
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.image-analysis-dashboard-bg-red {
    background-color: #fef2f2;
}

.image-analysis-dashboard-bg-yellow {
    background-color: #fefce8;
}

.image-analysis-dashboard-bg-blue {
    background-color: #eff6ff;
}

.image-analysis-dashboard-border-red {
    border-color: #fee2e2;
}

.image-analysis-dashboard-border-yellow {
    border-color: #fefcbf;
}

.image-analysis-dashboard-border-blue {
    border-color: #dbeafe;
}

.image-analysis-dashboard-item-message {
    font-size: 0.875rem;
    margin: 0;
    flex: 1;
}
