.media-search-results-wrapper {
    flex-grow: 1;
}

.media-search-results-tabs-wrapper {
    display: flex;
    gap: 12px;
    padding: 8px;
    align-items: center;
}

.media-search-results-container {
    width: 100%;
    justify-content: center;
    display: flex;
}

.media-image-analysis-container {
    display: flex;
    max-width: 748px;
    flex-shrink: 0;
}

.media-search-results-content-wrapper {
    display: flex;
}

@media (max-width: 1625px) {
    .media-image-analysis-container {
        max-width: 366px;
        width: unset;
    }
}

@media (max-width: 1024px) {
    .media-search-results-wrapper {
        margin-top: 60px;
    }

    .media-search-results-container {
        display: none;
    }

    .media-image-analysis-container {
        display: none;
        max-width: unset;
        width: 100%;
        justify-content: center;
    }

    .show-tab {
        display: flex;
    }

    .media-search-results-content-wrapper {
        display: flex;
    }
}