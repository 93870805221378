.media-analysis-form {
    width: 680px;
    padding-top: 80px;
}

.media-input-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
}

.media-input-title {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
}

.media-content-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.media-preview-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.preview-image-wrapper {
    max-width: 100%;
    max-height: 40vw;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .media-analysis-form {
        padding: 32px;
    }
  }