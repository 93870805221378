.dashboard-widget-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
}

.dashboard-widget-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.dashboard-widget-card-header {
    padding: 16px 20px 8px;
    border-bottom: 1px solid #f0f0f0;
}

.dashboard-widget-card-title {
    font-size: 16px;
    font-weight: 600;
    color: #4b5563;
    margin: 0;
}

.dashboard-widget-card-content {
    padding: 16px 20px;
    position: relative;

    transition: all 0.2s ease;
}

.dashboard-widget-card-content:hover {
    background-color: #f9fafb;

    transition: all 0.2s ease;
}