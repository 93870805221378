.media-analysis-form {
    width: 680px;
    padding-top: 80px;
}

.media-input-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
}

.media-input-title {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
}

.media-content-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.or-seperator {
    background-color: #ececec;
    padding: 6px 6px;
    height: 28px;
    width: 28px;
    color: #7c7c7c;
    border-radius: 50%;
    cursor: default;
    line-height: 16px;
}

.media-preview-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.preview-image-wrapper {
    max-width: 100%;
    max-height: 40vw;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.audio-analysis-result-container {
    margin-top: 32px;
    background-color: #F5F5F5;
    border-radius: 8px;
}


.text-analysis-result-body {
    padding: 16px;
}

.text-analysis-indicator-green {
    background-color: #06D6A0;
}

.text-analysis-indicator-yellow {
    background-color: #FFD166;
}

.text-analysis-indicator-red {
    background-color: #EF476F;
}

.title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

@media (max-width: 768px) {
    .media-analysis-form {
        padding: 96px 32px 32px 32px;
    }

    .title-wrapper {
        margin-bottom: 32px;
    }
}