.side-nav-bar {
  min-width: 280px;
  min-height: calc(100vh - 84px);
  height: auto;
  overflow-y: auto;
  background-color: #F5F5F5;
  z-index: 10;
  position: relative;
}

.side-nav-bar-mobile-collapsable {
  display: none;
}

.side-nav-bar-contact-us-wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  bottom: 0px;
  position: fixed;
  width: 280px;
}

.side-nav-bar-button-content-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .side-nav-bar {
    position: absolute;
    width: 100%;
    min-height: unset;
    height: 60px;
    overflow-y: hidden;

    transition: height 0.2s ease-in-out;
  }

  .side-nav-bar-contact-us-wrapper {
    position: absolute;
    width: 100%;
  }

  .side-bar-open {
    height: calc(100vh - 84px);

    transition: height 0.2s ease-in-out;
  }

  .side-nav-bar-mobile-collapsable {
    display: flex;
    background: #FFD166;
    font-weight: 700;
    font-size: 18px;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    align-items: center;
  }

  .side-nav-bar-mobile-collapsable:hover {
    background: #FFD166;
  }
}

@media (max-width: 768px) {
  .side-bar-open {
    height: calc(100vh - 60px);
  }
}