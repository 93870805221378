.analysis-alert {
  background: #fe3b3026;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.4rem;
}

.analysis-valid {
  background: #C2F5E4;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.4rem;
}

.right-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.analysis-container {
  position: relative;
}

.analysis-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  cursor: pointer;
}