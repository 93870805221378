.image-analysis-dashboard-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.image-analysis-dashboard-icon-container {
    background-color: #f9fafb;
    padding: 12px;
    border-radius: 9999px;
    margin-right: 16px;
}

.image-analysis-dashboard-icon {
    width: 24px;
    height: 24px;
    color: #9ca3af;
}

.image-analysis-dashboard-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.image-analysis-dashboard-link-icon {
    width: 16px;
    height: 16px;
    color: #9ca3af;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.image-analysis-dashboard-widget-container:hover .image-analysis-dashboard-link-icon {
    opacity: 1;
}

.image-analysis-dashboard-description {
    font-size: 0.875rem;
    color: #4b5563;
}

.image-analysis-dashboard-images-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

}

.image-analysis-dashboard-image-card {
    border-radius: 8px;
    border: 1px solid #f3f4f6;
    padding: 12px;
    background-color: #f9fafb;
}

.image-analysis-dashboard-image-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.image-analysis-dashboard-image-label {
    font-size: 12px;
    font-weight: 500;
    color: #374151;
}

.image-analysis-dashboard-image-icon {
    width: 16px;
    height: 16px;
    color: #8b5cf6;
}

.image-analysis-dashboard-image {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    opacity: 0.8;
}

.ai-analysis-widget-container {
    padding: 8px;
    border-radius: 8px;
}

.ai-analysis-widget-bg-green {
    background-color: #f0fdf4 !important;
}

.ai-analysis-widget-bg-red {
    background-color: #fef2f2 !important;
}

.ai-analysis-widget-bg-gray {
    background-color: #f9fafb !important;
}

@media (max-width: 1625px) {
    .image-analysis-dashboard-image-label {
        font-size: 11px;
    }
}