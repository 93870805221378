.coming-soon-container {
    display: flex;
    justify-content: center;
    margin-top: 200px;
}

.coming-soon-content-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 44px;
    color: #6a6a6a;
    align-items: center;
}

.atom-image {
    width: 300px;
    height: 300px;
}