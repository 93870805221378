.modal-wrapper {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-container {
  position: absolute;
  top: 10%;
  left: 50%;
  max-height: 80vh;
  transform: translate(-50%);
  border-radius: 8px;
  background-color: #fff;
  padding: 30px;
  max-width: 90%;
}

.modal-top-bar {
  display: flex;
  justify-content: end;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .modal-container {
    padding: 20px;
  }
}