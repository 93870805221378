.history-entry {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background-color: #ececec;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s ease;
  max-height: 60px;
  margin-bottom: 8px;
  overflow: hidden;
}

.history-entry-text {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background-color: #ececec;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s ease;
  cursor: pointer;
  max-height: 60px;
  margin-bottom: 8px;
  overflow: hidden;
}

/* .history-entry-text:hover {
  background-color: rgba(0, 0, 0, 0.2);
} */

.history-entry-description-container {
  display: flex;
  gap: 16px;
  max-width: calc(100% - 52px);
}

.history-entry-thumbnail {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.history-entry-description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 60px);
}

.history-entry-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 20px;
}

.history-entry-exec-time {
  font-size: 12px;
  color: #8a8a8a;
}

.history-entry-delete-button {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.history-entry:hover .history-entry-delete-button,
.history-entry-text:hover .history-entry-delete-button {
  opacity: 1;
}

.history-entry-delete-button:hover {
  background-color: #e0e0e0;
}

.history-entry-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.history-entry-action-button {
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.history-entry:hover .history-entry-action-button {
  opacity: 1;
}

@media (max-width: 768px) {
  .history-entry {
    border-radius: 8px;
    padding: 8px;
  }

  .history-entry-thumbnail {
    width: 44px;
    height: 44px;
  }

  .history-entry-exec-time {
    font-size: 11px;
  }

  .history-entry-delete-button {
    opacity: 1;
  }

  .history-entry-action-button {
    opacity: 1;
  }
}

.history-entry-disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .history-entry-delete-button {
    opacity: 1;
  }
}
