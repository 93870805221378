/* .audio-player {
  text-align: center;
  margin: 20px;
}

.segment-controls {
  margin-top: 10px;
}

.segment-controls .time-display {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.segment-controls button {
  padding: 5px 10px;
  cursor: pointer;
}

.rc-slider {
  margin: 20px auto;
  width: 80%;
} */

.audio-player {
  text-align: center;
  margin: 20px;
}

.segment-controls {
  margin-top: 10px;
}

.segment-controls .time-display {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.segment-controls button {
  padding: 5px 10px;
  cursor: pointer;
}

.rc-slider {
  margin: 20px auto;
  width: 80%;
}

.warning {
  color: red;
  margin-top: 10px;
}

.play-select-button {
  padding: 10px;
  margin: 10px 0;
  background-color: #FFD700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}