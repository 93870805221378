.ailight-button {
  padding: 9px;
  background-color: #FFCC34;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
  position: relative;
}

.ailight-button:hover {
  filter: brightness(95%);
  transition: filter 0.2s;
}

.ailight-button:disabled {
  background-color: #d0d0d0;
  filter: none;
  cursor: default;
}

.empty-ailight-button {
  background-color: transparent;
  border: none;
  color: #5b5b5b;
}

.empty-ailight-button:hover {
  background-color: #f0f0f0;
  transition: background-color 0.2s;
}

.button-spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-spinner {
  width: 24px;
  height: 24px;
  animation: spin 1.5s linear infinite;
  stroke: #FFCC33;
}