.tabs-container {
  display: none;
  grid-gap: 10px;
  gap: 10px;
  width: fit-content;
}

@media screen and (max-width: 1024px) {
  .tabs-container {
    display: flex;
  }
}