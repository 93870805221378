.metadata-container {
  /* max-height: 100%; */
  overflow-y: auto; /* Enable internal scrolling */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: relative;
  max-height: 71vh;
}
.metadata-container::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.analysis-valid {
  background: #C2F5E4;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.4rem;
}

.analysis-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.analysis-name {
  color: #3F8A63;
  font-weight: 600;
}

.metadata-chip {
  border-radius: 6px !important;
  background-color: #FFD700 !important;
  color: #000 !important;
}

.colored-box-icon {
  font-size: 30px;
  fill: green;
}

.meta-image-container {
  max-height: 714px;
  overflow-y: auto;
  width: 564px;
}

.tab-buttons {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
}

.tab-button {
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  text-transform: none;
  border: 1px solid #e0e0e0;
}

.tab-button.active {
  background-color: #FFD700;
  color: #000;
}

.insights-container {
  max-height: 714px;
  overflow-y: auto;
  width: 564px;
}

.insights-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
}

.insights-table th,
.insights-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.insights-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.metadata-table {
  width: 100%;
  border-collapse: collapse;
}

.metadata-table th {
  padding: 8px;
  text-align: left;
}

.metadata-table td {
  padding: 8px;
}

@media (max-width: 767px) {
  .meta-image-container {
    width: 100%;
    font-size: 14px;
  }

  .meta-image-container > table {
    border: none;
    font-size: 12px;
  }

  .insights-container {
    width: 100%;
    font-size: 14px;
  }

  .insights-table {
    font-size: 12px;
  }
}

.findings-list {
  padding-left: 1.5rem;
  margin: 0;
}

.finding-item {
  margin-bottom: 0.75rem;
  line-height: 1.5;
}

.no-findings {
  padding: 1rem;
  text-align: center;
  color: #666;
  font-style: italic;
}

.finding-text {
  cursor: help;
}

.metadata-legend-wrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  margin-top: 16px;
  align-items: center;
}

.metadata-legend-element {
  display: flex;
  gap: 4px;
  align-items: center;
}

.metadata-legend-circle {
  border-radius: 50%;
  border: 1px solid #000;
  width: 12px;
  height: 12px;
}

.metadata-legend-description {
  font-size: 14px;
  color: #8a8a8a;
}

.metadata-indicator-red {
  background-color: #EF476F;
}

.metadata-indicator-yellow {
  background-color: #FFD166;
}

.metadata-indicator-green {
  background-color: #06D6A0;
}

.finding-warning {
  background-color: #FFD166; /* Light yellow background for warnings */
  padding: 4px 8px;
  border-radius: 4px;
}

.finding-manipulated {
  background-color: #EF476F; /* Light red background for manipulated */
  padding: 4px 8px;
  border-radius: 4px;
}

.navigation-chip {
  border-radius: 6px !important;
  background-color: #FFD700 !important;
  color: #000 !important;
}

.chip-stack {
  margin-bottom: 8px !important;
}