.videos-search {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px;
  margin-block-start: 16px;
  max-width: 55%px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 80vh;
  margin-inline-start: 16px;
}

.videos-search-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.videos-search-header h3 {
  margin: 0;
  font-size: 20px;
}

.videos-search-header p {
  margin: 5px 0;
  color: grey;
}

.videos-search-input {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  width: 100%;
}

.videos-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  scrollbar-width: none;
}

.reset-analysis-button {
  position: absolute;
}

.video-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #F5F5F5;
  transition: background-color 0.2s;
}

.video-item:hover {
  background-color: #ffebb0;
  transition: background-color 0.2s;
}

.video-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px;
}

.video-thumbnail {
  width: 70px;
  height: 40px;
  object-fit: cover;
  margin-inline-end: 10px;
}

.icon-thumbnail {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 10px;
}

.video-link {
  color: blue;
  text-decoration: none;
  flex: 1;
  padding: 0px 8px;
}

.video-chevron {
  margin-left: 10px;
  font-weight: bold;
  color: grey;
}

@media (max-width: 1024px) {
  .videos-search {
    margin-block-start: 0px;
    box-shadow: none;
    width: 100%;
    padding: 10px;
    height: unset;
    margin-inline-start: 0px;
  }

  .visual-search-title {
    display: none;
  }

  .video-thumbnail {
    margin-inline-end: 0px;
  }

  .icon-thumbnail {
    position: absolute;
    top: 8px;
    left: 10px;
  }

  .video-link {
    font-size: 12px;
  }

  .video-item {
    min-height: 112px;
  }

  .MuiChip-labelMedium {
    font-size: 10px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .chip-stack {
    gap: 4px;
  }

  .chip-stack > div {
    margin: 0px !important;
  }

  .reset-analysis-button {
    display: none;
    padding: 0px;
  }
}

.left-aligned {
  position: absolute;
  left: 0;
  top: 15px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.search-results-container {
  position: relative;
}
