.source-reliability-widget-container {
  
}

.source-reliability-widget-empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 136px;
  color: #6b7280;
  text-align: center;
}

.source-reliability-widget-empty-icon {
  width: 32px;
  height: 32px;
  color: #9ca3af;
  margin-bottom: 12px;
}

.source-reliability-widget-content {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
}

.source-reliability-widget-status-icon {
  width: 24px;
  height: 24px;
}

.source-reliability-widget-green-icon {
  color: #16a34a;
}

.source-reliability-widget-red-icon {
  color: #dc2626;
}

.source-reliability-widget-yellow-icon {
  color: #eab308;
}

.source-reliability-widget-gray-icon {
  color: #6b7280;
}

.source-reliability-widget-bg-green {
  background-color: #f0fdf4;
}

.source-reliability-widget-bg-red {
  background-color: #fef2f2;
}

.source-reliability-widget-bg-yellow {
  background-color: #fefce8;
}

.source-reliability-widget-bg-gray {
  background-color: #f9fafb;
}

.source-reliability-widget-text {
  flex: 1;
}

.source-reliability-widget-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
  color: #111827;
}

.source-reliability-widget-description {
  font-size: 0.875rem;
  color: #4b5563;
  margin: 0;
  line-height: 1.5;
}

.source-reliability-widget-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.source-reliability-widget-button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6366f1;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.source-reliability-widget-button:hover {
  background-color: #eef2ff;
}

.source-reliability-widget-button-icon {
  width: 16px;
  height: 16px;
}