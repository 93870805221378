.login-wrapper {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 238px);
}

.login-background {
    position: fixed;
    top: -5px;
    left: -5px;
    width: 101%;
    height: 101%;
    background-size: cover;
    background-position: center;
    filter: blur(3px);
    z-index: -1;
    transform: translateZ(0);
}

.login-welcome-container {
    margin: 140px auto 60px auto;
    background-color: #333;
    padding: 40px;
    border-radius: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.login-welcome-content {
    width: 400px;
    margin: 20px auto 30px;
    padding-bottom: 16px;
    font-size: 40px;
    color: #fff;
    text-align: center;
    position: relative;
    display: inline-block;
    padding: 0 8px;
    color: #000;
    z-index: 1;
    align-self: center;
}

.login-welcome-content::before {
    content: '';
    position: absolute;
    top: 10%;
    /* Slightly offset from top */
    left: -2px;
    /* Extend slightly beyond text */
    width: calc(100% + 4px);
    /* Full width plus extra */
    height: 80%;
    /* Covers most of the text height */
    background-color: #FFCC33;
    /* Yellow marker color */
    z-index: -1;
    /* Behind text */
    transform: skew(-2deg) rotate(-1deg);
    /* Uneven slant */
    border-radius: 2px;
    /* Soft edges */
    opacity: 0.9;
}

/* Slightly translucent like a marker */

.login-form-container {
    margin-top: 84px;
    min-height: calc(100vh - 84px);
    flex: 0 0 50%;
    padding: 32px;
}

.login-form-content {
    width: 400px;
    margin: 116px auto 0px;
}

.login-form-full-width {
    width: 100%;
}

.login-title {
    font-size: 24px;
}

.login-form-separator-wrapper {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    position: relative;
}

.gdpr-logo {
    border-radius: 50%;
    width: 88px;
    height: 88px;
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    border: 3px solid #fff;
}

.login-form-separator-text {
    position: absolute;
    top: -10px;
    background-color: #333;
    text-align: center;
    width: 36px;
}

.login-form-separator-stroke {
    height: 1px;
    border-bottom: 1px solid #333;
    margin-bottom: -13px;
    width: 100%;
}

.login-form-separator-label {
    color: #d9d9d9;
    background-color: #fff;
    text-align: center;
    width: fit-content;
    padding: 0 8px;
}

.login-form-external-login-buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 52px;
}

.login-form-social-login-button {
    color: #fff;
    font-weight: bold;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 0.2s;
    cursor: pointer;
    line-height: 17px;
}

.login-form-social-login-button:hover {
    filter: brightness(90%);
    transition: filter 0.2s;
}

.login-svg-icon {
    stroke: #fff;
}

.google {
    background-color: #df4930;
}

.facebook {
    background-color: #507cc0;
}

.twitter {
    background-color: black;
}

.social-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.login-welcome-geometric {
    bottom: 0
}

.login-form-signup-button {
    font-weight: 700;
    width: 100%;
}

.login-form-login-button {
    font-weight: 700;
    width: 100%;
}

.sign-up-title {
    font-size: 24px;
    margin-bottom: 16px;
}

.sign-up-description {
    font-size: 18px;
}

.sign-up-description-wrapper {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 32px;
    color: #fff;
}

.sign-up-bullet {
    padding: 6px 8px;
    display: flex;
}

.sign-up-icon {
    margin-inline-end: 8px;
}

@media screen and (max-width: 950px) {
    .login-wrapper {
        flex-direction: column;
        flex: 1 1 0%;
    }

    .login-welcome-container {
        min-height: auto;
        margin-top: 96px;
        padding-bottom: 40px;
    }

    .login-form-container {
        min-height: auto;
        flex: 1 1 0%;
        margin-top: 0px;
    }

    .login-form-content {
        margin: 24px auto 0px;
    }
}

@media (max-width: 767px) {
    .login-welcome-content {
        font-size: 30px;
    }

    .login-welcome-container {
        margin: 76px 16px 52px 16px;
        padding: 16px;
    }

    .sign-up-description-wrapper {
        padding: 0;
    }

    .sign-up-title {
        font-size: 16px;
    }

    .sign-up-description {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .login-form-content {
        width: unset;
    }

    .login-welcome-content {
        width: unset;
        text-align: center;
    }
}