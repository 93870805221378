@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
.navbar {
  height: 100px;
  background-color: #dbde8ba1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.logo {
  font-size: 50px;
  font-weight: bold;
  margin: 50px auto;
}

.list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 50px auto;
}

.listItem {
  margin-right: 20px;
  font-size: 25px;
  font-weight: 500;
  cursor: pointer;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.home {
  display: flex;
  padding: 50px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  width: 30%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
}

.img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin: 20px 0px;
}

.desc {
  color: #333;
  margin-bottom: 20px;
  line-height: 25px;
}

.cardButton {
  border: none;
  border-radius: 10px;
  background-color: rgba(53, 16, 102, 0.678);
  padding: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.post {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postImg {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.postDesc {
  padding: 0 100px;
  font-size: 24px;
  font-weight: 300;
  color: gray;
  margin-top: 50px;
}

.postLongDesc {
  padding: 100px;
  padding-top: 50px;
  font-size: 18px;
  line-height: 24px;
}

.login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 80%;
  height: 75%;
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.login-input-wrapper {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.left,.right{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.center{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.or{
  border: 2px solid lightgray;
  border-radius: 50%;
  padding: 10px;
  color: gray;
  background-color: white;
  font-weight: bold;
}

.line{
  width: 0.5px;
  height: 70%;
  background-color: lightgray;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.login-button{
  width: 196px;
  padding: 15px 40px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  transition: filter 0.2s;
}

.login-button:hover {
  filter: brightness(90%);
  transition: filter 0.2s;
}

input{
  width: 200px;
  padding: 15px 30px;
  transition: border-color 0.2s;
}

input:hover, input:focus{
  border-color: #8a8a8a;
  transition: border-color 0.2s;
}

.submit {
  width: 200px;
  background-color: #FFCC33;
  color: black;
  font-weight: bold;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  /* align text to center of button*/
  justify-content: center;  
  transition: filter 0.2s;
}

.submit:hover {
  filter: brightness(95%);
  transition: filter 0.2s;
}

.link{
  color: inherit;
  text-decoration: none;
}

.login-msg{
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

.container {
  display: flex;
}

.leftHome {
  padding-top: 140px;
  flex: 0 0 2%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.rightHome {
  padding-top: 130px;
  padding-left: 130px;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.result-container {
  flex-grow: 1;
}

.imageSearchContainer{
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.polaroid {
  /* width: 250px; */
  /* width: 90%;
  padding: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
div img {
  /* width: 100%; */
  height: 100%;
  object-fit: cover; /* or 'contain' depending on your needs */
}

footer {
    background-color: #f1f1f1;
    text-align: center;
    padding: 20px 0;
    width: 100%;
    bottom: 0;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
}

.main-container {
  display: flex;
}

.hyper-link {
  color: hotpink;
  font-size: 14px;
  cursor: pointer;
}

.hyper-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1279px) {
  .wrapper {
    width: 80%;
  }
    
  .login-input-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .center {
    display: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin-top: 16px;
    padding: 40px 16px;
  }

  .login {
    flex-direction: column;
    height: unset;
  }

  footer {
    padding: 0;
  }

  .login-button {
    margin-bottom: 0;
  }

  .login-msg{
    margin: 0;
  }

  .result-container {
    width: 100%;
  }
}
/* TopBar Styles */
.top-bar {
  top: 0;
  /* Align the top edge of the bar with the top of the viewport */
  left: 0;
  /* Align the left edge of the bar with the left of the viewport */
  width: 100%;
  /* Make it full width */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #333333;
  z-index: 1;
  /* more options: #333333 (charcoal gray), 
  /* z-index: 1000;    Ensure it sits on top of other content */
}

.fixed-bar {
  position: fixed;
}

.ailight-logo {
  width: 240px;
  /* Adjust as needed */
  height: 60px;
  margin: 0;
  align-items: center;
}

.main-logo {
  padding: 0 12px;
}

.top-bar-right {
  display: flex;
  align-items: center;
}

.user-name {
  margin-right: 10px;
  font-size: 30px;
  color: black;
  font-weight: bold;
}

.user-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: solid #FFCC34;
  border-width: 4px;
  transition: transform 0.3s ease, border-width 0.3s ease;
}

.user-image:hover {
  transform: scale(1.1); /* Slightly enlarges the image */
}

.logout-icon {
  margin-inline-end: 50px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: none;
}

.top-bar-front-layer {
  position: absolute;
  z-index: 10000;
}

@media (max-width: 767px) {
  body {
    margin: 0;
  }

  .user-image {
    width: 44px;
    height: 44px;
  }

  .logout-icon {
    margin-inline-end: 0px;
    width: 32px !important;
    height: 32px !important;
  }

  .ailight-logo {
    width: 124px;
    height: 60px;
  }

  .top-bar {
    padding: 0 12px;
  }

  .main-logo {
    padding: 6px;
  }
}
.side-nav-bar {
  min-width: 280px;
  min-height: calc(100vh - 84px);
  height: auto;
  overflow-y: auto;
  background-color: #F5F5F5;
  z-index: 10;
  position: relative;
}

.side-nav-bar-mobile-collapsable {
  display: none;
}

.side-nav-bar-contact-us-wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  grid-gap: 16px;
  gap: 16px;
  bottom: 0px;
  position: fixed;
  width: 280px;
}

.side-nav-bar-button-content-wrapper {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .side-nav-bar {
    position: absolute;
    width: 100%;
    min-height: unset;
    height: 60px;
    overflow-y: hidden;

    transition: height 0.2s ease-in-out;
  }

  .side-nav-bar-contact-us-wrapper {
    position: absolute;
    width: 100%;
  }

  .side-bar-open {
    height: calc(100vh - 84px);

    transition: height 0.2s ease-in-out;
  }

  .side-nav-bar-mobile-collapsable {
    display: flex;
    background: #FFD166;
    font-weight: 700;
    font-size: 18px;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    align-items: center;
  }

  .side-nav-bar-mobile-collapsable:hover {
    background: #FFD166;
  }
}

@media (max-width: 768px) {
  .side-bar-open {
    height: calc(100vh - 60px);
  }
}
.ailight-button {
  padding: 9px;
  background-color: #FFCC34;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
  position: relative;
}

.ailight-button:hover {
  filter: brightness(95%);
  transition: filter 0.2s;
}

.ailight-button:disabled {
  background-color: #d0d0d0;
  filter: none;
  cursor: default;
}

.empty-ailight-button {
  background-color: transparent;
  border: none;
  color: #5b5b5b;
}

.empty-ailight-button:hover {
  background-color: #f0f0f0;
  transition: background-color 0.2s;
}

.button-spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-spinner {
  width: 24px;
  height: 24px;
  animation: spin 1.5s linear infinite;
  stroke: #FFCC33;
}
.nav-element {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  align-items: center;

  transition: 0.2s background-color ease-in-out;
}

.nav-element:hover {
  background-color: #e9e9e9;
  transition: 0.2s background-color ease-in-out;
}

.description-wrapper {
  display: flex;
  font-size: 18px;
  grid-gap: 8px;
  gap: 8px;
}

.chevron-icon {
  transition: transform 0.2s ease-in-out;
}

.chevron-icon-active {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.nav-item-list {
  max-height: 0px;
  overflow: hidden;

  transition: 0.2s max-height ease-in-out;
}

.nav-item-list-open {
  max-height: 195px;

  transition: 0.2s max-height ease-in-out;
}
.nav-item {
  display: flex;
  align-items: center;
  line-height: 17px;
  padding: 8px;
  padding-inline-start: 32px;
  cursor: pointer;
  grid-gap: 8px;
  gap: 8px;
  
  transition: 0.2s background-color ease-in-out;
}

.nav-item:hover {
  background-color: #e9e9e9;
  transition: 0.2s background-color ease-in-out;
}

.nav-item-active {
  cursor: default;
  background-color: #FFCC34;
}

.nav-item-active:hover {
  background-color: #FFCC34;
}
.simple-dropdown-list {
  list-style: none;
  margin: 8px 0 0 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
  border-radius: 5px;
  background: #333333;
  overflow: hidden;
  border: 1px solid #f9f9f9;
  width: 200px;
}

.simple-dropdown-item {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  color: #fff;
  background: #333333;
  transition: background-color 0.2s ease-in-out;
}

.simple-dropdown-item:hover {
  background-color: #474747;
  transition: background-color 0.2s ease-in-out;
}

.simple-dropdown-item-read-only {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  color: #ccc;
  background: #333333;
  cursor: default;
}

.simple-dropdown-item-separator {
  border-top: 1px solid #f9f9f9;;
}

.simple-dropdown-item-icon {
  stroke: #fff;
}
.full-element-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.basic-spinner {
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
    stroke: #FFCC33;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
/* .audio-player {
  text-align: center;
  margin: 20px;
}

.segment-controls {
  margin-top: 10px;
}

.segment-controls .time-display {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.segment-controls button {
  padding: 5px 10px;
  cursor: pointer;
}

.rc-slider {
  margin: 20px auto;
  width: 80%;
} */

.audio-player {
  text-align: center;
  margin: 20px;
}

.segment-controls {
  margin-top: 10px;
}

.segment-controls .time-display {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.segment-controls button {
  padding: 5px 10px;
  cursor: pointer;
}

.rc-slider {
  margin: 20px auto;
  width: 80%;
}

.warning {
  color: red;
  margin-top: 10px;
}

.play-select-button {
  padding: 10px;
  margin: 10px 0;
  background-color: #FFD700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}
.media-select-modal-footer-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.media-select-modal-action-buttons-wrapper {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}
.main-work-area-wrapper {
  width: inherit;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 84px);
  height: auto;
}

@media (max-width: 768px) {
  .main-work-area-wrapper {
    min-height: calc(100vh - 60px);
    height: auto;
  }
}
.media-analysis-form {
    width: 680px;
    padding-top: 80px;
}

.media-input-wrapper {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
}

.media-input-title {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
}

.media-content-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.media-preview-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.preview-image-wrapper {
    max-width: 100%;
    max-height: 40vw;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .media-analysis-form {
        padding: 32px;
    }
  }
.media-search-results-wrapper {
    flex-grow: 1;
}

.media-search-results-tabs-wrapper {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    padding: 8px;
    align-items: center;
}

.media-search-results-container {
    width: 100%;
    justify-content: center;
    display: flex;
}

.media-image-analysis-container {
    display: flex;
    max-width: 748px;
    flex-shrink: 0;
}

.media-search-results-content-wrapper {
    display: flex;
}

@media (max-width: 1625px) {
    .media-image-analysis-container {
        max-width: 366px;
        width: unset;
    }
}

@media (max-width: 1024px) {
    .media-search-results-wrapper {
        margin-top: 60px;
    }

    .media-search-results-container {
        display: none;
    }

    .media-image-analysis-container {
        display: none;
        max-width: unset;
        width: 100%;
        justify-content: center;
    }

    .show-tab {
        display: flex;
    }

    .media-search-results-content-wrapper {
        display: flex;
    }
}
.similarity-search-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  max-height: 80vh;
  padding: 0 24px;
  position: relative;
  max-width: 1100px;
}

.videos-search-header {
  flex-shrink: 0; /* Prevents the header from shrinking */
  padding: 10px;
}

.videos-list-container {
  flex: 1 1;
  overflow-y: auto; /* Enable vertical scrolling */
  min-height: 0; /* Important for Firefox */
  max-height: calc(80vh - 120px); /* Adjust based on header height */
}

.video-item-wrapper {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
}

/* Add styles for modal context */
.modal-container .similarity-search-results {
  max-height: 100%;
  overflow: hidden;
}

.modal-container .videos-list-container {
  max-height: calc(100% - 120px);
  overflow-y: auto;
}

.search-entry {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.2s;
    border: 1px solid #f0f0f0;
    cursor: pointer;
}

.search-entry:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    background: #f9f9f9;
}

.entry-media {
    position: relative;
    width: 120px;
    min-width: 120px;
    height: 120px;
    overflow: hidden;
}

.entry-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.search-entry:hover .entry-thumbnail {
    transform: scale(1.05);
}

.fake-warning {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(255, 82, 82, 0.9);
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.entry-content {
    flex: 1 1;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.entry-source {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 6px;
}

.source-logo {
    width: 16px;
    height: 16px;
    object-fit: contain;
    border-radius: 3px;
}

.source-domain {
    font-size: 12px;
    color: #666;
}

.entry-title {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.4;
    text-align: start;
}

.entry-link {
    color: #333;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.entry-link:hover {
    color: #4f46e5;
}

.external-icon {
    opacity: 0.6;
}

.entry-metadata {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 12px;
}

.metadata-item {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    font-size: 12px;
    color: #666;
}

.similarity {
    border-radius: 16px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
}

.similarity-value {
    font-weight: 600;
    font-size: 13px;
}

.similarity-label {
    margin-left: 4px;
    font-size: 11px;
    opacity: 0.8;
}

.similarity.similarity-high {
    background-color: rgba(52, 211, 153, 0.15);
    color: #047857;
}

.similarity.similarity-medium {
    background-color: rgba(251, 191, 36, 0.15);
    color: #b45309;
}

.similarity.similarity-low {
    background-color: rgba(239, 68, 68, 0.15);
    color: #b91c1c;
}

.entry-badges {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
    position: absolute;
    bottom: 12px;
}

.entry-title-container {
    max-width: 800px;
    overflow: hidden;
    width: 100%;
}

.entry-title-text {
    font-weight: 400;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.entry-similarity {
    align-self: center;
    margin-inline-end: 12px;
}

.badge-container {
    display: inline-flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
}

.badge-icon {
    width: 14px;
    height: 14px;
}

.badge-reliability {
    background-color: #f3f4f6;
    color: #374151;
}

.badge-high {
    background-color: rgba(52, 211, 153, 0.15);
    color: #047857;
}

.badge-medium {
    background-color: rgba(251, 191, 36, 0.15);
    color: #b45309;
}

.badge-low {
    background-color: rgba(239, 68, 68, 0.15);
    color: #b91c1c;
}

.badge-social {
    background-color: rgba(79, 70, 229, 0.15);
    color: #4338ca;
}

.badge-warning {
    background-color: rgba(239, 68, 68, 0.15);
    color: #b91c1c;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.similarity-progressbar {
    width: 70px;
    height: 70px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .search-entry {
        flex-direction: column;
    }

    .entry-media {
        width: 100%;
        height: 180px;
    }

    .entry-metadata {
        flex-wrap: wrap;
    }

    .entry-title-text {
        line-clamp: unset;
        -webkit-line-clamp: unset;
        box-orient: unset;
        -webkit-box-orient: unset;
    }

    .entry-similarity {
        align-self: end;
        margin-inline-end: 12px;
        margin-bottom: 12px;
    }

    .entry-badges {
        bottom: -68px;
    }
}
.tabs-container {
  display: none;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (max-width: 1024px) {
  .tabs-container {
    display: flex;
  }
}
.tab-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 24px;
    cursor: pointer;

    transition: background-color 0.2s ease-in-out;
}

.tab-container:hover {
    background-color: #edecfe;

    transition: background-color 0.2s ease-in-out;
}

.tab-container-selected {
    background-color: #E5E3FB;

    transition: background-color 0.2s ease-in-out;
}

.tab-icon {
    stroke: #707479;
    width: 20px;

    transition: stroke 0.2s ease-in-out;
}

.tab-icon-selected {
    stroke: #4338ca;

    transition: stroke 0.2s ease-in-out;
}

.tab-label {
    color: #707479;
    font-size: 14px;

    transition: color 0.2s ease-in-out;
}

.tab-label-selected {
    color: #4338ca;

    transition: color 0.2s ease-in-out;
}

@media (max-width: 768px) {
    .tab-label {
        font-size: 12px;
        line-height: 13px;
    }

    .tab-container {
        grid-gap: 6px;
        gap: 6px;
        padding: 8px 12px;
    }

    .tab-icon {
        width: 16px;
    }
}
.image-analysis-dashboard-container {
  padding: 24px;
  background-color: #f9fafb;
  min-height: 100vh;
}

.image-analysis-dashboard-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  color: #1f2937;
}

.image-analysis-dashboard-grid {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  max-width: 92rem;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .image-analysis-dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
    align-content: start;
  }
}

@media (min-width: 1024px) {
  .image-analysis-dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .image-analysis-dashboard-container {
    min-height: unset;
    padding: 12px 24px 0 0;
  }
}

@media (max-width: 1625px) {
  .image-analysis-dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.image-analysis-dashboard-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.image-analysis-dashboard-icon-container {
    background-color: #f9fafb;
    padding: 12px;
    border-radius: 9999px;
    margin-right: 16px;
}

.image-analysis-dashboard-icon {
    width: 24px;
    height: 24px;
    color: #9ca3af;
}

.image-analysis-dashboard-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.image-analysis-dashboard-link-icon {
    width: 16px;
    height: 16px;
    color: #9ca3af;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.image-analysis-dashboard-widget-container:hover .image-analysis-dashboard-link-icon {
    opacity: 1;
}

.image-analysis-dashboard-description {
    font-size: 0.875rem;
    color: #4b5563;
}

.image-analysis-dashboard-images-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    gap: 16px;

}

.image-analysis-dashboard-image-card {
    border-radius: 8px;
    border: 1px solid #f3f4f6;
    padding: 12px;
    background-color: #f9fafb;
}

.image-analysis-dashboard-image-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.image-analysis-dashboard-image-label {
    font-size: 12px;
    font-weight: 500;
    color: #374151;
}

.image-analysis-dashboard-image-icon {
    width: 16px;
    height: 16px;
    color: #8b5cf6;
}

.image-analysis-dashboard-image {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    opacity: 0.8;
}

.ai-analysis-widget-container {
    padding: 8px;
    border-radius: 8px;
}

.ai-analysis-widget-bg-green {
    background-color: #f0fdf4 !important;
}

.ai-analysis-widget-bg-red {
    background-color: #fef2f2 !important;
}

.ai-analysis-widget-bg-gray {
    background-color: #f9fafb !important;
}

@media (max-width: 1625px) {
    .image-analysis-dashboard-image-label {
        font-size: 11px;
    }
}
.dashboard-widget-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
}

.dashboard-widget-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.dashboard-widget-card-header {
    padding: 16px 20px 8px;
    border-bottom: 1px solid #f0f0f0;
}

.dashboard-widget-card-title {
    font-size: 16px;
    font-weight: 600;
    color: #4b5563;
    margin: 0;
}

.dashboard-widget-card-content {
    padding: 16px 20px;
    position: relative;

    transition: all 0.2s ease;
}

.dashboard-widget-card-content:hover {
    background-color: #f9fafb;

    transition: all 0.2s ease;
}
.ai-detection-widget-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 16px;
    overflow: hidden;
}

.ai-detection-widget-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ai-detection-widget-card-header {
    padding: 16px 20px 8px;
    border-bottom: 1px solid #f0f0f0;
}

.ai-detection-widget-status-header-container {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.ai-detection-widget-source-image {
    width: 72px;
    height: 72px;
    border-radius: 8px;
}

.ai-detection-widget-card-title {
    font-size: 16px;
    font-weight: 600;
    color: #4b5563;
    margin: 0;
}

.ai-detection-widget-card-content {
    padding: 16px 20px;
}

.ai-detection-widget-result {
    margin-bottom: 16px;
}

.ai-detection-widget-status-container {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
}

.ai-detection-widget-status-icon {
    width: 24px;
    height: 24px;
}

.ai-detection-widget-status-text {
    flex: 1 1;
}

.ai-detection-widget-status-heading {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.ai-detection-widget-status-detail {
    font-size: 14px;
    color: #6b7280;
    margin: 0;
}

.ai-icon,
.ai-generated {
    color: #7A5FEF;
}

.human-icon,
.human-created {
    color: #047857;
}

.uncertain-icon,
.uncertain {
    color: #6b7280;
}

.ai-detection-widget-confidence-container {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.ai-detection-widget-confidence-labels {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.ai-detection-widget-confidence-label {
    color: #6b7280;
}

.ai-detection-widget-confidence-value {
    font-weight: 500;
    color: #374151;
}

.ai-detection-widget-thin-progress-bar-bg {
    width: 100%;
    height: 8px;
    background-color: #ececec;
    border-radius: 999px;
    overflow: hidden;
}

.ai-detection-widget-thin-progress-bar {
    height: 100%;
    border-radius: 999px;
    transition: width 0.5s ease;
}

.ai-detection-widget-low-confidence {
    background-color: #c8c8c8;
}

.ai-detection-widget-moderate-confidence {
    background-color: #c8c8c8;
}

.ai-detection-widget-high-confidence {
    background-color: #c8c8c8;
}

.ai-detection-widget-very-high-confidence {
    background-color: #c8c8c8;
}
/* MetadataAnalysisWidget.css */
.image-analysis-dashboard-widget-container {

}

.image-analysis-dashboard-widget-container:hover {
    background-color: #f9fafb;
}

/* Empty State */
.image-analysis-dashboard-empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: #6b7280;
    text-align: center;
}

.image-analysis-dashboard-empty-icon {
    width: 32px;
    height: 32px;
    color: #9ca3af;
    margin-bottom: 12px;
}

/* Header */
.image-analysis-dashboard-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
}

.image-analysis-dashboard-icon-container {
    padding: 10px;
    border-radius: 9999px;
    margin-right: 16px;
    display: flex;
}

.image-analysis-dashboard-status-icon {
    width: 20px;
    height: 20px;
}

.image-analysis-dashboard-red-icon {
    color: #dc2626;
}

.image-analysis-dashboard-yellow-icon {
    color: #eab308;
}

.image-analysis-dashboard-blue-icon {
    color: #2563eb;
}

.image-analysis-dashboard-title-container {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.image-analysis-dashboard-title {
    font-size: 1rem;
    font-weight: 600;
}

.image-analysis-dashboard-text-red {
    color: #991b1b;
}

.image-analysis-dashboard-text-yellow {
    color: #854d0e;
}

.image-analysis-dashboard-text-blue {
    color: #1e40af;
}

.image-analysis-dashboard-badge {
    font-size: 0.75rem;
    padding: 2px 8px;
    border-radius: 9999px;
}

.image-analysis-dashboard-badge-red {
    background-color: #fee2e2;
    color: #991b1b;
}

.image-analysis-dashboard-badge-yellow {
    background-color: #fefcbf;
    color: #854d0e;
}

.image-analysis-dashboard-badge-blue {
    background-color: #dbeafe;
    color: #1e40af;
}

.image-analysis-dashboard-description {
    font-size: 0.875rem;
    color: #4b5563;
    margin-top: 4px;
}

.image-analysis-dashboard-link-icon {
    width: 16px;
    height: 16px;
    color: #9ca3af;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.image-analysis-dashboard-widget-container:hover .image-analysis-dashboard-link-icon {
    opacity: 1;
}

/* Items List */
.image-analysis-dashboard-items-container {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.image-analysis-dashboard-item {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid;
    display: flex;
    align-items: flex-start;
    grid-gap: 12px;
    gap: 12px;
}

.image-analysis-dashboard-bg-red {
    background-color: #fef2f2;
}

.image-analysis-dashboard-bg-yellow {
    background-color: #fefce8;
}

.image-analysis-dashboard-bg-blue {
    background-color: #eff6ff;
}

.image-analysis-dashboard-border-red {
    border-color: #fee2e2;
}

.image-analysis-dashboard-border-yellow {
    border-color: #fefcbf;
}

.image-analysis-dashboard-border-blue {
    border-color: #dbeafe;
}

.image-analysis-dashboard-item-message {
    font-size: 0.875rem;
    margin: 0;
    flex: 1 1;
}

.source-reliability-widget-container {
  
}

.source-reliability-widget-empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 136px;
  color: #6b7280;
  text-align: center;
}

.source-reliability-widget-empty-icon {
  width: 32px;
  height: 32px;
  color: #9ca3af;
  margin-bottom: 12px;
}

.source-reliability-widget-content {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
}

.source-reliability-widget-status-icon {
  width: 24px;
  height: 24px;
}

.source-reliability-widget-green-icon {
  color: #16a34a;
}

.source-reliability-widget-red-icon {
  color: #dc2626;
}

.source-reliability-widget-yellow-icon {
  color: #eab308;
}

.source-reliability-widget-gray-icon {
  color: #6b7280;
}

.source-reliability-widget-bg-green {
  background-color: #f0fdf4;
}

.source-reliability-widget-bg-red {
  background-color: #fef2f2;
}

.source-reliability-widget-bg-yellow {
  background-color: #fefce8;
}

.source-reliability-widget-bg-gray {
  background-color: #f9fafb;
}

.source-reliability-widget-text {
  flex: 1 1;
}

.source-reliability-widget-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
  color: #111827;
}

.source-reliability-widget-description {
  font-size: 0.875rem;
  color: #4b5563;
  margin: 0;
  line-height: 1.5;
}

.source-reliability-widget-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.source-reliability-widget-button {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #6366f1;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.source-reliability-widget-button:hover {
  background-color: #eef2ff;
}

.source-reliability-widget-button-icon {
  width: 16px;
  height: 16px;
}

:root {
  --primary: #FFCC33;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.main-container {
  width: 100%;
  display: flex;
}

.left-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.center-container {
  height: 100%;
  width: 26%;
  background-color: rgba(245, 245, 245, 255);
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
  box-shadow: 0px 19px 37px 0px rgba(0, 0, 0, 0.1);
  grid-gap: 10px;
  gap: 10px;
}
.video-text {
  font-size: 21px;
  font-weight: 500;
}
.find-videos {
  font-size: 16px;
  font-weight: 400;
  color: rgba(124, 124, 124, 1);
  margin-top: 8px;
}
.center-container-wrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}

.img-container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}

.input-video {
  height: 47px;
  width: 100%;
  outline: none;
  border: none;
  background: rgba(255, 255, 255, 1);
  padding: 10px 15px;
  border-radius: 12px;
}

.img-video {
  height: 54px;
  width: 100px;
  border-radius: 8.76px;
}

.videos-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  grid-gap: 5px;
  gap: 5px;
  overflow-y: scroll;
}
.videos-container::-webkit-scrollbar {
  display: none;
}
.video-location-link {
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
  color: rgba(3, 126, 229, 1);
}

.video-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 19px 30px;
}
.icon-white {
  color: white;
}
.icon-grey {
  color: grey;
}
.left-video-box {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.video-box:hover {
  background-color: var(--primary);
}
.video-box.active {
  background-color: var(--primary);
}

.right-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
  justify-items: center;
  height: -moz-fit-content;
  height: fit-content;
}

.card-wrapper {
  position: relative;
}

.colored-box {
  min-height: 100%;
  width: 163px;
  background: rgba(194, 245, 228, 1);
  border-radius: 12px;
  padding: 19px 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.green-box {
  background: rgba(194, 245, 228, 1);
}
.yellow-box {
  background: rgb(248, 250, 137);
}
.red-box {
  background: rgba(254, 59, 48, 0.15);
}
.gray-box {
  background: rgba(166, 167, 165, 0.15);
}

.colored-box-text {
  font-size: 16px;
  color: rgba(63, 138, 99, 1);
  font-weight: 600;
  text-align: center;
}
.red-text {
  color: rgba(254, 59, 48, 1);
}
.yellow-text {
  color: gray;
}
.colored-box-icon {
  font-size: 30px;
  fill: green;
}
.colored-box-icon-red-icon {
  fill: red;
}
.colored-box-icon-gray-icon {
  fill: rgb(119, 119, 86);
}

.green-button {
  cursor: pointer;
  background-color: rgba(239, 255, 252, 1);
  color: rgba(64, 134, 94, 1);
  border: none;
  border-radius: 6px;
  padding: 5px 11px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
}
.red-button {
  cursor: pointer;
  background-color: #fff4f1;
  border: none;
  border-radius: 6px;
  padding: 5px 11px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(254, 59, 48, 1);
}

.back-btn {
  all: unset;
  padding: 10px 20px;
  border-radius: 8px;
  background: var(--primary);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  width: -moz-fit-content;
  width: fit-content;
}

.mobile-reset-analysis-button {
  padding: 6px 16px;
}

@media screen and (max-width: 1024px) {
  .main-container {
    flex-wrap: wrap;
  }

  .left-container,
  .center-container,
  .right-container {
    width: 100% !important;
    padding: 10px !important;
  }

  .right-container { 
    background-color: #f9f9f9;
  }

  .left-container img {
    width: 100%;
  }

  .videos-container {
    height: -moz-fit-content;
    height: fit-content;
  }
}

.tab-wrapper {
  display: none;
}

@media (max-width: 767px) {
  .main-container {
    grid-gap: 0px;
    gap: 0px;
    grid-gap: 0px;
  }

  .mobile-main-container-margin {
    margin-top: 60px;
  }

  .tab-wrapper {
    display: flex;
    width: 100%;
    background-color: #d0d0d0;
    padding: 0 4px 0 0;
  }

  .tab {
    flex: 1 1;
    background-color: #d0d0d0;
    padding: 8px;
    cursor: pointer;
    height: 34px;
    margin-top: 6px;
  }

  .selected {
    background: #f9f9f9;
    border-radius: 8px 8px 0px 0px;
  }

  .mobile-hide {
    display: none !important;
  }

  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.videos-search {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px;
  margin-block-start: 16px;
  max-width: 55%px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 80vh;
  margin-inline-start: 16px;
}

.videos-search-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.videos-search-header h3 {
  margin: 0;
  font-size: 20px;
}

.videos-search-header p {
  margin: 5px 0;
  color: grey;
}

.videos-search-input {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  width: 100%;
}

.videos-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  scrollbar-width: none;
}

.reset-analysis-button {
  position: absolute;
}

.video-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #F5F5F5;
  transition: background-color 0.2s;
}

.video-item:hover {
  background-color: #ffebb0;
  transition: background-color 0.2s;
}

.video-item-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  padding: 0px;
}

.video-thumbnail {
  width: 70px;
  height: 40px;
  object-fit: cover;
  margin-inline-end: 10px;
}

.icon-thumbnail {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 10px;
}

.video-link {
  color: blue;
  text-decoration: none;
  flex: 1 1;
  padding: 0px 8px;
}

.video-chevron {
  margin-left: 10px;
  font-weight: bold;
  color: grey;
}

@media (max-width: 1024px) {
  .videos-search {
    margin-block-start: 0px;
    box-shadow: none;
    width: 100%;
    padding: 10px;
    height: unset;
    margin-inline-start: 0px;
  }

  .visual-search-title {
    display: none;
  }

  .video-thumbnail {
    margin-inline-end: 0px;
  }

  .icon-thumbnail {
    position: absolute;
    top: 8px;
    left: 10px;
  }

  .video-link {
    font-size: 12px;
  }

  .video-item {
    min-height: 112px;
  }

  .MuiChip-labelMedium {
    font-size: 10px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .chip-stack {
    grid-gap: 4px;
    gap: 4px;
  }

  .chip-stack > div {
    margin: 0px !important;
  }

  .reset-analysis-button {
    display: none;
    padding: 0px;
  }
}

.left-aligned {
  position: absolute;
  left: 0;
  top: 15px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.search-results-container {
  position: relative;
}

.video-description-container {
  background-color: #f0f0f0;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 12px;
  border: solid 2px #FFCC34;
  overflow: hidden;
  cursor: pointer;
}

.vidoe-description-play-button {
  height: 48px;
  padding: 8px;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
  background: #FFCC34;
  border-radius: 50% 0 0 50%;

  transition: all 0.2s ease-in-out, border-radius 0.4s ease-in-out;
}

.video-description-container:hover .vidoe-description-play-button {
  right: 50%;
  transform: translate(50%, -50%);
  background: #FFCC34;
  border-radius: 50% 50% 50% 50%;

  transition: all 0.2s ease-in-out;
}

.video-description-play-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  stroke: #f0f0f0;
}

.vidoe-description-title {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #8a8a8a;
  line-height: 12px;
  padding: 3px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}
.media-preview-input-wrapper {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  align-items: center;
}

.media-preview-content-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.media-preview-or-separator {
  background-color: #ececec;
  padding: 6px 6px;
  height: 28px;
  width: 28px;
  color: #7c7c7c;
  border-radius: 50%;
  cursor: default;
  line-height: 16px;
}

.media-preview-container {
  margin-top: 16px;
  margin-bottom: 32px;
}

.media-preview-image-wrapper {
  display: flex;
  justify-content: center;
}

.preview-image-container {
  max-width: 100%;
  max-height: 40vh;
}
.text-analysis-wrapper {
    width: 680px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
}

.text-area-content {
    width: 100%;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: none;
    padding: 8px;
    min-height: 128px;
}

.char-counter {
    display: flex;
    justify-content: end;
    margin-top: -33px;
    font-size: 14px;
    color: #8a8a8a;
    padding: 0 16px 4px 0;
}

.text-analysis-result-title-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    width: 75%;
}

.text-analysis-result-description {
    color: #8a8a8a;
    font-size: 14px;
}

.text-analysis-title {
    font-weight: 700;
    font-size: 20px;
}

.text-analysis-result-container {
    background-color: #F5F5F5;
    border-radius: 8px;
}

.text-analysis-result-header {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    border-bottom: solid 1px #ccc;
    align-items: center;
    min-height: 107px;
    position: relative;
}

.text-analysis-result-indicator-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.text-analysis-result-indicator {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 4px #f9f9f9;
    transition: all 0.2s ease-in-out;
}

.text-analysis-result-indicator-label {
    height: 24px;
    min-width: 52px;
    margin-inline-end: -16px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    padding-inline-end: 22px;
    font-size: 16px;
    text-transform: uppercase;
    padding-inline-start: 10px;
    line-height: 25px;
    font-size: 14px;

    transition: all 0.2s ease-in-out;
}

.text-analysis-result-indicator-label:hover {
    height: 36px;
    line-height: 36px;

    transition: all 0.2s ease-in-out;
}

.text-analysis-indicator-green {
    background-color: #06D6A0;
    color: #2c2c2c;
}

.text-analysis-indicator-yellow {
    background-color: #FFD166;
    color: #2c2c2c;
}

.text-analysis-indicator-red {
    background-color: #EF476F;
    color: #fff;
}

.text-analysis-result-body-wrapper {
    max-height: 0px;
    overflow: hidden;

    transition: 0.2s max-height ease-in-out;
}

.text-analysis-result-body-open {
    max-height: 1600px;

    transition: 0.2s max-height ease-in-out;
}

.highlighted-text {
    border-radius: 5px;
    margin-inline-end: 4px;
    cursor: default;
    border: 1px solid #F5F5F5;
    padding: 2px 4px;

    transition: all 0.2s ease-in-out;
}

.highlighted-text:hover {
    border: 1px solid #3a3a3a;

    transition: all 0.2s ease-in-out;
}

.highlighted-text-medium {
    background-color: #FFD166;
}

.highlighted-text-high {
    background-color: #EF476F;
}

.text-analysis-legend-wrapper {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    margin-top: 16px;
}

.text-analysis-legend-element {
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
}

.text-analysis-legend-circle {
    border-radius: 50%;
    border: 1px solid #000;
    width: 12px;
    height: 12px;
}

.text-analysis-legend-description {
    font-size: 14px;
    color: #8a8a8a;
}

.text-analysis-details-button {
    position: absolute;
    bottom: -29px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d0d0d0;
    padding: 2px 8px;
    border-radius: 5px;
    cursor: pointer;
}

.text-analysis-more-chevron {
    transform: rotate(-90deg);
    transition: transform 0.2s ease-in-out;
}

.text-analysis-more-chevron-open {
    transform: rotate(90deg);
    transition: transform 0.2s ease-in-out;
}

.text-analysis-body-container {
    padding: 16px;
}

.textarea-container {
    position: relative;
    width: 100%;
}

.textarea-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.textarea-loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .text-analysis-wrapper {
        padding: 80px 24px 32px 24px;
        grid-gap: 20px;
        gap: 20px;
    }

    .text-area-content {
        height: 232px;
    }

    .text-analysis-result-title-wrapper {
        width: 64%;
    }

    .text-analysis-result-title {
        font-size: 15px;
    }

    .text-analysis-result-header {
        padding: 10px;
    }
}
.coming-soon-container {
    display: flex;
    justify-content: center;
    margin-top: 200px;
}

.coming-soon-content-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 44px;
    color: #6a6a6a;
    align-items: center;
}

.atom-image {
    width: 300px;
    height: 300px;
}
.media-analysis-form {
    width: 680px;
    padding-top: 80px;
}

.media-input-wrapper {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
}

.media-input-title {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
}

.media-content-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.or-seperator {
    background-color: #ececec;
    padding: 6px 6px;
    height: 28px;
    width: 28px;
    color: #7c7c7c;
    border-radius: 50%;
    cursor: default;
    line-height: 16px;
}

.media-preview-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.preview-image-wrapper {
    max-width: 100%;
    max-height: 40vw;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.audio-analysis-result-container {
    margin-top: 32px;
    background-color: #F5F5F5;
    border-radius: 8px;
}


.text-analysis-result-body {
    padding: 16px;
}

.text-analysis-indicator-green {
    background-color: #06D6A0;
}

.text-analysis-indicator-yellow {
    background-color: #FFD166;
}

.text-analysis-indicator-red {
    background-color: #EF476F;
}

.title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

@media (max-width: 768px) {
    .media-analysis-form {
        padding: 96px 32px 32px 32px;
    }

    .title-wrapper {
        margin-bottom: 32px;
    }
}
.full-screen-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.basic-spinner {
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
    stroke: #FFCC33;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
.recurring-media-top-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    align-items: center;
    padding: 16px 0;
}

.recurring-media-container {
    width: 100%;
    max-width: 880px;
    padding: 0 16px;
}

.periodic-jobs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    padding: 12px;
}

.recurring-media-add-media-wrapper {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
}

@media (max-width: 768px) {
    .recurring-media-add-media-wrapper {
        flex-direction: column-reverse;
        align-items: end;
        width: 128px;
    }

    .media-input-title {
        align-self: end;
        line-height: 42px;
    }
}
.periodic-entry {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background-color: #ececec;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.periodic-entry-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  display: flex;
  align-items: center;
}

.periodic-entry-searching-disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

.periodic-entry-description-container {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  max-width: 80%;
}

.periodic-entry-thumbnail {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.periodic-entry-description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 60px);
  overflow: hidden;
}

.periodic-entry-description {
  align-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
}

.periodic-entry-next-run {
  font-size: 12px;
  color: #8a8a8a;
}

.periodic-entry-delete-button {
  height: 44px;
  opacity: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.periodic-entry-actions {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.periodic-entry-action-button {
  white-space: nowrap;
  opacity: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.periodic-entry-delete-button,
.periodic-entry-action-button {
  transition: opacity 0.2s ease-in-out;
}

.periodic-entry:hover .periodic-entry-delete-button,
.periodic-entry-disabled:hover .periodic-entry-delete-button,
.periodic-entry:hover .periodic-entry-action-button {
  opacity: 1;
}


@media (max-width: 768px) {
  .periodic-entry {
    border-radius: 8px;
    padding: 8px;
  }

  .periodic-entry-description-container {
    max-width: 50%;
  }

  .periodic-entry-thumbnail {
    width: 44px;
    height: 44px;
  }

  .periodic-entry-next-run {
    font-size: 11px;
  }

  .periodic-entry-delete-button,
  .periodic-entry-action-button {
    opacity: 1;
  }
}

@media print {
  .periodic-entry {
    background-color: white;
    border: 1px solid #ddd;
  }

  .periodic-entry-delete-button,
  .periodic-entry-action-button {
    display: none;
  }
}

.recurring-media-empty-state-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    grid-gap: 20px;
    gap: 20px;
}

.recurring-media-empty-state-title {
    font-size: 24px;
}

.recurring-media-empty-state-description {
    font-size: 14px;
    text-align: center;
    color: #8a8a8a;
}

.recurring-media-empty-state-image {
    width: 50%;
    max-width: 512px
}
.FactCheckResult_factContainer__3pMlA {
  border: 0cqmax solid #ccc;
  border-radius: 5px;
  padding: 1px;
  margin-bottom: 10px;
}

.FactCheckResult_factText__1qV_f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

.FactCheckResult_factContent__2JBxu {
  flex-grow: 1;
}

.FactCheckResult_arrow__WXOtv {
  font-size: 18px;
  padding-left: 10px;
}

.FactCheckResult_collapsibleContent__3FZoq {
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.FactCheckResult_upArrow__2D9mw {
  transform: rotate(270deg);
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

.FactCheckResult_downArrow__3Ld3H {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

.FactCheckResult_iconX__1CKP3 {
  width: 24px;
  height: 24px;
  margin-top: -2px;
  flex-shrink: 0;
}

.FactCheckResult_iconV__bUPu5 {
  width: 20px;
  height: 20px;
  margin-top: -6px;
  flex-shrink: 0;
}
.FactCheckSearchResults_searchResult__1OTLk {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

.FactCheckSearchResults_searchResult__1OTLk a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.FactCheckSearchResults_searchResult__1OTLk img {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  border-radius: 3px;
}

.FactCheckSearchResults_iconX__r45KI {
  width: 24px;
  height: 24px;
  margin-right: 2px;
  flex-shrink: 0;
}

.FactCheckSearchResults_iconV__1_12a {
  width: 18px;
  height: 18px;
  margin-top: -6px;
  margin-left: 5px;
  margin-right: 2px;
  flex-shrink: 0;
}
.usage-history-top-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    align-items: center;
    padding: 16px 0;
}

.usage-history-container {
    width: 100%;
    max-width: 880px;
    padding: 0 16px;
}

.usage-history-list {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.usage-history-add-media-wrapper {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
}

@media (max-width: 768px) {
    .usage-history-add-media-wrapper {
        flex-direction: column-reverse;
        align-items: end;
        width: 128px;
    }

    .media-input-title {
        align-self: end;
        line-height: 42px;
    }
}

.text-image-thumbnail {
    background-color: white;
}
.history-entry {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background-color: #ececec;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s ease;
  max-height: 60px;
  margin-bottom: 8px;
  overflow: hidden;
}

.history-entry-text {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background-color: #ececec;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s ease;
  cursor: pointer;
  max-height: 60px;
  margin-bottom: 8px;
  overflow: hidden;
}

/* .history-entry-text:hover {
  background-color: rgba(0, 0, 0, 0.2);
} */

.history-entry-description-container {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  max-width: calc(100% - 52px);
}

.history-entry-thumbnail {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.history-entry-description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 60px);
}

.history-entry-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 20px;
}

.history-entry-exec-time {
  font-size: 12px;
  color: #8a8a8a;
}

.history-entry-delete-button {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.history-entry:hover .history-entry-delete-button,
.history-entry-text:hover .history-entry-delete-button {
  opacity: 1;
}

.history-entry-delete-button:hover {
  background-color: #e0e0e0;
}

.history-entry-actions {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.history-entry-action-button {
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.history-entry:hover .history-entry-action-button {
  opacity: 1;
}

@media (max-width: 768px) {
  .history-entry {
    border-radius: 8px;
    padding: 8px;
  }

  .history-entry-thumbnail {
    width: 44px;
    height: 44px;
  }

  .history-entry-exec-time {
    font-size: 11px;
  }

  .history-entry-delete-button {
    opacity: 1;
  }

  .history-entry-action-button {
    opacity: 1;
  }
}

.history-entry-disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .history-entry-delete-button {
    opacity: 1;
  }
}

.connect-media-top-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    align-items: center;
    padding: 16px 0;
}

.connect-media-container {
    width: 100%;
    max-width: 880px;
    padding: 0 16px;
}

.periodic-jobs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    padding: 12px;
}

.connect-media-add-media-wrapper {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
    transition: all 0.3s ease;
}

.media-input-description {
    color: #6a6a6a;
    font-size: 14px;
    max-width: 400px;
    min-width: 200px;
}

.periodic-jobs-list-description {
    font-size: 14px;
    color: #6a6a6a;
    margin-top: 16px;
}

.connect-media-container button:focus,
.connect-media-container input:focus {
    outline: 2px solid #4a90e2;
    outline-offset: 2px;
}

.periodic-jobs-list-empty {
    text-align: center;
    padding: 24px;
    color: #6a6a6a;
    font-style: italic;
}

@media (max-width: 768px) {
    .connect-media-add-media-wrapper {
        flex-direction: column-reverse;
        align-items: end;
        width: 95%;
    }

    .media-input-title {
        align-self: end;
        line-height: 42px;
    }

}

@media (max-width: 480px) {
    .connect-media-container {
        padding: 0 8px;
    }

    .media-input-description {
        min-width: unset;
    }
}
.login-wrapper {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 238px);
}

.login-background {
    position: fixed;
    top: -5px;
    left: -5px;
    width: 101%;
    height: 101%;
    background-size: cover;
    background-position: center;
    filter: blur(3px);
    z-index: -1;
    transform: translateZ(0);
}

.login-welcome-container {
    margin: 140px auto 60px auto;
    background-color: #333;
    padding: 40px;
    border-radius: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.login-welcome-content {
    width: 400px;
    margin: 20px auto 30px;
    padding-bottom: 16px;
    font-size: 40px;
    color: #fff;
    text-align: center;
    position: relative;
    display: inline-block;
    padding: 0 8px;
    color: #000;
    z-index: 1;
    align-self: center;
}

.login-welcome-content::before {
    content: '';
    position: absolute;
    top: 10%;
    /* Slightly offset from top */
    left: -2px;
    /* Extend slightly beyond text */
    width: calc(100% + 4px);
    /* Full width plus extra */
    height: 80%;
    /* Covers most of the text height */
    background-color: #FFCC33;
    /* Yellow marker color */
    z-index: -1;
    /* Behind text */
    transform: skew(-2deg) rotate(-1deg);
    /* Uneven slant */
    border-radius: 2px;
    /* Soft edges */
    opacity: 0.9;
}

/* Slightly translucent like a marker */

.login-form-container {
    margin-top: 84px;
    min-height: calc(100vh - 84px);
    flex: 0 0 50%;
    padding: 32px;
}

.login-form-content {
    width: 400px;
    margin: 116px auto 0px;
}

.login-form-full-width {
    width: 100%;
}

.login-title {
    font-size: 24px;
}

.login-form-separator-wrapper {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    position: relative;
}

.gdpr-logo {
    border-radius: 50%;
    width: 88px;
    height: 88px;
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    border: 3px solid #fff;
}

.login-form-separator-text {
    position: absolute;
    top: -10px;
    background-color: #333;
    text-align: center;
    width: 36px;
}

.login-form-separator-stroke {
    height: 1px;
    border-bottom: 1px solid #333;
    margin-bottom: -13px;
    width: 100%;
}

.login-form-separator-label {
    color: #d9d9d9;
    background-color: #fff;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 8px;
}

.login-form-external-login-buttons-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 52px;
}

.login-form-social-login-button {
    color: #fff;
    font-weight: bold;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 0.2s;
    cursor: pointer;
    line-height: 17px;
}

.login-form-social-login-button:hover {
    filter: brightness(90%);
    transition: filter 0.2s;
}

.login-svg-icon {
    stroke: #fff;
}

.google {
    background-color: #df4930;
}

.facebook {
    background-color: #507cc0;
}

.twitter {
    background-color: black;
}

.social-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.login-welcome-geometric {
    bottom: 0
}

.login-form-signup-button {
    font-weight: 700;
    width: 100%;
}

.login-form-login-button {
    font-weight: 700;
    width: 100%;
}

.sign-up-title {
    font-size: 24px;
    margin-bottom: 16px;
}

.sign-up-description {
    font-size: 18px;
}

.sign-up-description-wrapper {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 32px;
    color: #fff;
}

.sign-up-bullet {
    padding: 6px 8px;
    display: flex;
}

.sign-up-icon {
    margin-inline-end: 8px;
}

@media screen and (max-width: 950px) {
    .login-wrapper {
        flex-direction: column;
        flex: 1 1;
    }

    .login-welcome-container {
        min-height: auto;
        margin-top: 96px;
        padding-bottom: 40px;
    }

    .login-form-container {
        min-height: auto;
        flex: 1 1;
        margin-top: 0px;
    }

    .login-form-content {
        margin: 24px auto 0px;
    }
}

@media (max-width: 767px) {
    .login-welcome-content {
        font-size: 30px;
    }

    .login-welcome-container {
        margin: 76px 16px 52px 16px;
        padding: 16px;
    }

    .sign-up-description-wrapper {
        padding: 0;
    }

    .sign-up-title {
        font-size: 16px;
    }

    .sign-up-description {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .login-form-content {
        width: unset;
    }

    .login-welcome-content {
        width: unset;
        text-align: center;
    }
}
.login-footer-container {
  display: flex;
  grid-gap: 52px;
  gap: 52px;
  background-color: #333333;
  padding: 24px 10%;
}

.login-footer-description {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.login-footer-menu {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.login-footer-description-text {
  color: #fff;
  font-family: "Days One";
  text-align: start;
}

.login-footer-title {
  font-family: "Days One", Sans-serif !important;
  font-size: 17px;
  font-weight: 700;
  border: solid 2px #FFCC33;
  color: #FFCC34;
  padding: 0 8px;
  width: -moz-fit-content;
  width: fit-content;
}

.login-footer-logo {
  max-width: 68px;
}

.login-footer-link {
  display: flex;
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .login-footer-container{
    grid-gap: 28px;
    gap: 28px;
  }

  .login-footer-logo {
    max-width: 52px;
  }

  .login-footer-description-text {
    font-size: 14px;
  }

  .login-footer-container {
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .login-footer-container {
    flex-direction: column;
    padding: 16px 5%;
  }
}
.success-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f9f9f9;
}

.success-page-central-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px;
    background: #fff;
    border-radius: 16px;
    margin: 0 16px;
}

.success-page-success-icon {
    width: 88px;
    height: 88px;
    stroke: #06D6A0;
}

.success-page-welcome-message {
    margin-top: 50px;
    font-size: 24px;
    width: 100%;
}

.success-page-description {
    font-size: 14px;
    color: #8a8a8a;
    margin-top: 12px;
}

.success-page-highlighted-text {
    color: #FFCC33;
}

@media (max-width: 600px) {
    .success-page-central-container {
        padding: 24px;
    }
}
.plan-select-wrapper {
    display: flex;
    justify-content: center;
    background-color: #f9f9f9;
    min-height: 100vh;
    height: auto;
    padding: 16px;
}

.plan-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 32px;
    gap: 32px;
}

.plan-select-cards-container {
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
}

.plan-select-title {
    margin-top: 130px;
    font-size: 20px;
    font-weight: 700;
}

@media (max-width: 768px) {
    .plan-select-cards-container {
        flex-direction: column;
    }

    .plan-select-title {
        margin-top: 72px;
    }
}
.plan-card-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
  border-radius: 10px;
  background-color: #fff;
  align-items: center;
  min-height: 560px;
  position: relative;
}

.plan-card-title {
  font-size: 20px;
  font-weight: 700;
}

.plan-card-price {
  font-size: 32px;
  font-weight: 700;
}

.plan-card-subtitle {
  font-size: 14px;
}

.plan-prices-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.plan-card-details-list-entry-wrapper {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.plan-card-details-list {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.plan-card-details-list-entry-bullet {
  width: 12px;
  height: 12px;
}

.plan-card-details-list-entry {
  font-size: 14px;
  line-height: 14px;
}

.plan-card-details-per-month {
  font-size: 14px;
  color: #6a6a6a;
}

.plan-card-select-button {
  position: absolute;
  bottom: 17px;
  width: calc(100% - 32px);
}


@media (max-width: 768px) {
  .plan-card-container {
    width: calc(100vw - 60px);
    min-height: 484px;
  }
}

body {
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  width: inherit;
}

form {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

button {
  margin: 0 5px;
}
.modal-wrapper {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-container {
  position: absolute;
  top: 10%;
  left: 50%;
  max-height: 80vh;
  transform: translate(-50%);
  border-radius: 8px;
  background-color: #fff;
  padding: 30px;
  max-width: 90%;
}

.modal-top-bar {
  display: flex;
  justify-content: end;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .modal-container {
    padding: 20px;
  }
}
.analysis-alert {
  background: #fe3b3026;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.4rem;
}

.analysis-valid {
  background: #C2F5E4;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.4rem;
}

.right-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.analysis-container {
  position: relative;
}

.analysis-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  cursor: pointer;
}
.navigation-label {
  margin-bottom: 16px;
}
.modal-content {
  width: 100%;
  height: 50vh;
  overflow: hidden;
}

.navigation-label {
  margin-bottom: 16px;
}

.image-container {
  max-height: 50vh;
  overflow-y: auto;
  margin-right: auto;
  margin-left: auto;
}
.metadata-container {
  /* max-height: 100%; */
  overflow-y: auto; /* Enable internal scrolling */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: relative;
  max-height: 71vh;
}
.metadata-container::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.analysis-valid {
  background: #C2F5E4;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.4rem;
}

.analysis-title {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.analysis-name {
  color: #3F8A63;
  font-weight: 600;
}

.metadata-chip {
  border-radius: 6px !important;
  background-color: #FFD700 !important;
  color: #000 !important;
}

.colored-box-icon {
  font-size: 30px;
  fill: green;
}

.meta-image-container {
  max-height: 714px;
  overflow-y: auto;
  width: 564px;
}

.tab-buttons {
  display: flex;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.tab-button {
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  text-transform: none;
  border: 1px solid #e0e0e0;
}

.tab-button.active {
  background-color: #FFD700;
  color: #000;
}

.insights-container {
  max-height: 714px;
  overflow-y: auto;
  width: 564px;
}

.insights-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
}

.insights-table th,
.insights-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.insights-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.metadata-table {
  width: 100%;
  border-collapse: collapse;
}

.metadata-table th {
  padding: 8px;
  text-align: left;
}

.metadata-table td {
  padding: 8px;
}

@media (max-width: 767px) {
  .meta-image-container {
    width: 100%;
    font-size: 14px;
  }

  .meta-image-container > table {
    border: none;
    font-size: 12px;
  }

  .insights-container {
    width: 100%;
    font-size: 14px;
  }

  .insights-table {
    font-size: 12px;
  }
}

.findings-list {
  padding-left: 1.5rem;
  margin: 0;
}

.finding-item {
  margin-bottom: 0.75rem;
  line-height: 1.5;
}

.no-findings {
  padding: 1rem;
  text-align: center;
  color: #666;
  font-style: italic;
}

.finding-text {
  cursor: help;
}

.metadata-legend-wrapper {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  margin-top: 16px;
  align-items: center;
}

.metadata-legend-element {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
}

.metadata-legend-circle {
  border-radius: 50%;
  border: 1px solid #000;
  width: 12px;
  height: 12px;
}

.metadata-legend-description {
  font-size: 14px;
  color: #8a8a8a;
}

.metadata-indicator-red {
  background-color: #EF476F;
}

.metadata-indicator-yellow {
  background-color: #FFD166;
}

.metadata-indicator-green {
  background-color: #06D6A0;
}

.finding-warning {
  background-color: #FFD166; /* Light yellow background for warnings */
  padding: 4px 8px;
  border-radius: 4px;
}

.finding-manipulated {
  background-color: #EF476F; /* Light red background for manipulated */
  padding: 4px 8px;
  border-radius: 4px;
}

.navigation-chip {
  border-radius: 6px !important;
  background-color: #FFD700 !important;
  color: #000 !important;
}

.chip-stack {
  margin-bottom: 8px !important;
}
.modal-video-wrapper {
}
.modal-create-recurring-wrapper {
    width: 680px;
    max-width: 100%;
}

.modal-create-recurring-footer {
    display: flex;
    margin-top: 26px;
    justify-content: space-between;
}

.inputs-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    grid-gap: 24px;
    gap: 24px;
}

.frame-grid-wrapper {
    max-height: 53vh;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .inputs-wrapper {
        flex-direction: column;
    }

    .frame-grid-wrapper {
        max-height: 29vh;
    }
  }
.radio-options-container {
  display: flex;
  flex-direction: row;
  grid-gap: 16px;
  gap: 16px;
}

.radio-option-wrapper {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.radio-option {
  width: 20px;
  height: 20px;
}

.radio-option-label {

}
.tawk-to-chat-bot-wrapper {
}
.required-payment-warning-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.required-payment-warning-description {
    color: #6a6a6a;
    font-size: 14px;
    max-width: 400px;
    min-width: 200px;
    margin-bottom: 48px;
}

.required-payment-warning-button {
    width: 100%;
    background-color: #06D6A0;
}
.modal-container {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: hidden;
}

.modal-top-bar {
  flex-shrink: 0;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-create-connect-media-wrapper {
    width: 680px;
    max-width: 100%;
}

.modal-create-connect-media-footer {
    display: flex;
    margin-top: 26px;
    justify-content: space-between;
}

.input-loader-container {
    position: relative;
    width: 100%;
}

.full-width-button {
    width: 100%;
}

@media (max-width: 768px) {
    .inputs-wrapper {
        flex-direction: column;
    }
}
.alert-modal-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.alert-modal-description {
    color: #6a6a6a;
    font-size: 14px;
    max-width: 400px;
    min-width: 200px;
    margin-bottom: 20px;
}

.alert-modal-button {
    width: 100%;
    background-color: #06D6A0;
}
.settings-wrapper {
    margin-top: 84px;
    height: calc(100vh - 84px);
    background: #f1f1f1;
    display: flex;
}

.settings-container {
    background: #fff;
    padding: 24px;
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 100px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    position: relative;
    overflow: hidden;
}

.settings-avatar-banner {
    width: 100%;
    height: 100px;
    /* Or any height you want */
    background: red;
    /* This will be overridden by .area, but keep it for fallback */
    border-radius: 8px;
    position: relative;
    /* Make this the positioning context for .area and .circles */
    overflow: hidden;
    /* Ensure circles don't overflow outside the banner */
}

.settings-avatar {
    border-radius: 50%;
    border: solid 4px #fff;
    margin-top: -50px;
    height: 100px;
    width: 100px;
    z-index: 1;
}

.settings-user-name {
    margin-top: 24px;
    font-weight: 700;
}

.settings-user-email {
    font-size: 13px;
    color: #9d9d9d;
}

.settings-plan-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    grid-gap: 63px;
    gap: 63px;
}

.settings-info-box {
    display: flex;
    align-items: center;
    justify-items: center;
    grid-gap: 4px;
    gap: 4px;
    flex-direction: column;
    padding: 13px;
    background: #f1f1f1;
    border-radius: 8px;
    width: 150px;
}

.settings-info-box-big {
    font-size: 16px;
    font-weight: 700;
}

.settings-info-box-small {
    font-size: 13px;
    color: #9d9d9d;
}

.settings-deactivate {
    margin-top: 44px;
    align-self: start;
    width: 100%;
    color: #5b5b5b;
    background-color: transparent;
}

.settings-activate {
    margin-top: 44px;
    align-self: start;
    width: 100%;
    background-color: #FFCC34;
    color: #000;
}

@media (max-width: 768px) {
    .settings-wrapper {
        margin-top: 60px;
        height: calc(100vh - 60px);
    }

    .settings-container {
        margin: 50px 0 20px 20px;
        width: calc(100% - 40px);
        height: -moz-fit-content;
        height: fit-content;
    }

    .settings-plan-container {
        grid-gap: 22px;
        gap: 22px;
    }

    .settings-info-box-big {
        font-size: 14px;
    }
}

.area {
    background: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-100%) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
