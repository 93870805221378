.nav-element {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  align-items: center;

  transition: 0.2s background-color ease-in-out;
}

.nav-element:hover {
  background-color: #e9e9e9;
  transition: 0.2s background-color ease-in-out;
}

.description-wrapper {
  display: flex;
  font-size: 18px;
  gap: 8px;
}

.chevron-icon {
  transition: transform 0.2s ease-in-out;
}

.chevron-icon-active {
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
}

.nav-item-list {
  max-height: 0px;
  overflow: hidden;

  transition: 0.2s max-height ease-in-out;
}

.nav-item-list-open {
  max-height: 195px;

  transition: 0.2s max-height ease-in-out;
}