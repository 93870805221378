.plan-select-wrapper {
    display: flex;
    justify-content: center;
    background-color: #f9f9f9;
    min-height: 100vh;
    height: auto;
    padding: 16px;
}

.plan-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.plan-select-cards-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.plan-select-title {
    margin-top: 130px;
    font-size: 20px;
    font-weight: 700;
}

@media (max-width: 768px) {
    .plan-select-cards-container {
        flex-direction: column;
    }

    .plan-select-title {
        margin-top: 72px;
    }
}